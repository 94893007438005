import { Navigate, Outlet } from "react-router-dom";

import React, {useState} from "react";
import Sidebar from "./Sidebar";
import { getToken } from "../../../helpers/util";

const PrivateLayout = () => {
  const auth = getToken();
  const [isOpen, setOpen] = useState(true);

  return auth && auth?.access_token ? (
    <>
      <div className="relative mx-auto max-w-[1920px] overflow-x-hidden">
        <div className={`sidebar-wrapper ${isOpen ? "" : "sidebar-wrapper--closed"}`}>
          <Sidebar.Header setOpen={setOpen} isOpen={isOpen} />
          <Sidebar.Menu isOpen={isOpen} />
          <Sidebar.Footer isOpen={isOpen} />
        </div>

        <div
          className={`relative z-[1] ml-[64px] flex min-h-screen flex-col p-8 transition-all ${isOpen ? "lg:ml-[256px]" : "lg:ml-[64px]"} `}
        >
          <div className="relative z-[1] mx-auto flex min-h-screen w-full max-w-screen-xl flex-col">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateLayout;
