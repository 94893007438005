import "react-modern-calendar-datepicker/lib/DatePicker.css";

import React, { useEffect, useState } from "react";

import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import Dialog from "rc-dialog";
import Input from "./input";
import dayjs from "dayjs";

const DatePicker = ({ value, ...props }) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(value || null);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClearDates = () => {
    setSelectedDay(null);
  };

  useEffect(() => {
    // console.log(selectedDay)
    props.onChange(selectedDay);
  // eslint-disable-next-line
  }, [selectedDay]);

  return (
    <>
      <input
        type="hidden"
        value={
          selectedDay &&
          dayjs(
            `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
          ).format("YYYY-MM-DDTHH:mm:ss")
        }
        {...props}
      />
      <Input
        onClick={showModal}
        value={
          selectedDay &&
          dayjs(
            `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
          ).format("YYYY-MM-DD")
        }
      />

      <Dialog
        visible={isOpen}
        className="!max-w-md"
        onClose={handleCancel}
        closeIcon={() => <></>}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div className="flex rounded-md bg-white">
          <div className={`relative z-0 mx-auto pt-5 mx-4`}>
            <Calendar
              value={selectedDay}
              onChange={setSelectedDay}
              colorPrimary="#00BBA7"
              colorPrimaryLight="#C0EAED"
              // className="shadow-0"
              calendarClassName="custom-calendar !shadow-none"
              renderFooter={() => {
                return (
                  <div className="flex items-center justify-between !py-4 text-sm">
                    <div className="flex flex-col space-y-1 text-[#33475B]">
                      <table>
                        <tr>
                          <td className="text-right">
                            <span className="font-[400] text-cyan-400">
                              Date:
                            </span>
                          </td>
                          <td className="!pl-2">
                            <span className="font-medium">
                              {selectedDay &&
                                dayjs(
                                  `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
                                ).format("DD MMMM YYYY")}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="">
                      <button
                        type="button"
                        onClick={handleCancel}
                        // disabled={!selectedDay}
                        className={`inline-block items-center ${
                          selectedDay || !selectedDay
                            ? "bg-orange-400 text-white"
                            : "bg-[#F2EAFA] text-[#33475B] opacity-80"
                        } rounded-full !px-4 !py-2 text-sm `}
                      >
                        <span>Done</span>
                      </button>
                    </div>
                  </div>
                );
              }}
            />

            <button
              type="button"
              onClick={handleClearDates}
              className="absolute right-0 top-[1.75rem] z-10 text-sm text-cyan-500 hover:text-cyan-500"
            >
              Clear
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DatePicker;
