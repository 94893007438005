import React, {ReactNode} from 'react';

import styled from 'styled-components';
import {useLocation} from 'react-router-dom';

const DisableItem = styled.span``;
const Item = styled.button``;

// interface IProps {
//   label?: number | string | ReactNode;
//   value: number;
//   currentPage: number;
//   setPage: (page: number) => void;
//   disabled?: boolean;
//   shadow?: boolean;
//   children?: any;
// }

const PageItem = props => {
  const {search} = useLocation();

  const {disabled, currentPage, setPage, value, label, shadow = true} = props;

  const queryParams = new URLSearchParams(search);

  queryParams.set('page', `${value}`);

  return disabled ? (
    <DisableItem
      className={`inline-flex justify-center leading-4 rounded-md min-w-[20px] ${shadow && 'shadow'} ${
        currentPage === value
          ? 'bg-[#425B76]/30 text-white'
          : 'bg-gray-100 text-[#425B76]'
      } py-[2px] px-[6px] cursor-not-allowed select-none`}
    >
      {label}
    </DisableItem>
  ) : (
    <Item
      type="button"
      onClick={() => setPage(value)}
      className={`inline-flex justify-center leading-4 rounded-md min-w-[20px] ${shadow && 'shadow'} ${
        currentPage === value
          ? 'bg-[#425B76] border-transparent text-white cursor-default'
          : 'bg-white text-[#425B76] cursor-pointer'
      } py-[2px] px-[6px] select-none`}
    >
      {label}
    </Item>
  );
};

export default PageItem;
