import React, { useRef, useState } from "react";

const TagSelect = ({ id, defaultChecked = false, value, onChange, title }) => {
  const [checked, setChecked] = useState(defaultChecked);
  const checkboxRef = useRef(null);

  return (
    <>
      <input
        id={id}
        type="checkbox"
        className="hidden"
        onChange={(e) => setChecked(!checked)}
        checked={checked}
        value={value}
        defaultChecked={defaultChecked}
        ref={checkboxRef}
      />
      <label
        htmlFor={id}
        className={` cursor-pointer rounded-full px-4 py-2 ${
          checked ? "bg-[#49C2CB] text-white" : "bg-[#E6F7F8]  text-[#7B93AF]"
        }`}
      >
        {title}
      </label>
    </>
  );
};

export default TagSelect;
