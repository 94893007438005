import request from "../helpers/request";

function createTeam(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .post(`/api/adminV3/create-team`, params);
}

function updateTeam(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .put(`/api/adminV3/update-team`, params);
}

function createUser(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .post(`/api/adminV3/create-user`, params);
}

function updateUser(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .put(`/api/adminV3/update-user`, params);
}

const userManagementServices = {
  createTeam,
  updateTeam,
  createUser,
  updateUser,
};

export default userManagementServices;
