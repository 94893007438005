import { Swiper, SwiperSlide } from "swiper/react";

import BarChart from "./BarChart";
import { Navigation } from "swiper";
import React from "react";
import Switch from "../../shared/switch/Switch";
import TagSelect from "../../shared/tagSelect/TagSelect";

const Response = () => {
  return (
    <>
      <div className="flex justify-between space-x-4">
        <div className="flex-grow">
          <div className="flex gap-2">
            <TagSelect value="all" id="all" title="ALL" />
            <TagSelect value="all2" id="all2" title="ALL2" />
            <TagSelect value="all3" id="all3" title="ALL3" />
          </div>
        </div>
        <div className="flex-shrink-0">
          <div className="flex justify-end space-x-4">
            <Switch
              defaultValue={"off"}
              options={[
                { label: "On", value: "on" },
                { label: "Jibe Agent Off", value: "off" },
              ]}
            />

            <Switch
              defaultValue={"agent"}
              options={[
                { label: "Customer", value: "customer" },
                { label: "Agent", value: "agent" },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="mt-16">
        <Swiper
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={3}
          //   onSlideChange={() => console.log("slide change")}
          //   onSwiper={(swiper) => console.log(swiper)}
          navigation={{
            nextEl: ".change-channel-btn__next",
            prevEl: ".change-channel-btn__prev",
          }}
        >
          <SwiperSlide>
            <div className="max-h-[150px] overflow-hidden overflow-y-auto scrollbar-thin scrollbar-track-neutral-100 scrollbar-thumb-[#70CFD6]">
              <BarChart
                data={[
                  { name: "test1", percentage: 23, color: "#64C18F" },
                  { name: "test2", percentage: 67, color: "#E2E2E8" },
                  {
                    name: "test3",
                    percentage: 5,
                    color: "rgba(250, 26, 93, 0.74)",
                  },
                  { name: "test4", percentage: 28, color: "#5BC8D0" },
                  { name: "test5", percentage: 23, color: "#64C18F" },
                  { name: "test6", percentage: 67, color: "#E2E2E8" },
                  {
                    name: "test7",
                    percentage: 5,
                    color: "rgba(250, 26, 93, 0.74)",
                  },
                  { name: "test8", percentage: 28, color: "#5BC8D0" },
                ]}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="max-h-[150px] overflow-hidden overflow-y-auto scrollbar-thin scrollbar-track-neutral-100 scrollbar-thumb-[#70CFD6]">
              <BarChart
                data={[
                  { name: "Satisfied", percentage: 28, color: "#49C2CB" },
                  { name: "Unsure", percentage: 67, color: "#B68FE5" },
                  {
                    name: "#425B76",
                    percentage: 5,
                    color:
                      "linear-gradient(91.89deg, #F2547D -6.8%, #FF7959 100.34%)",
                  },
                ]}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="max-h-[150px] overflow-hidden overflow-y-auto scrollbar-thin scrollbar-track-neutral-100 scrollbar-thumb-[#70CFD6]">
              <BarChart
                data={[
                  {
                    name: "Yes",
                    percentage: 67,
                    color: "#7B93AF",
                  },
                  { name: "No", percentage: 28, color: "#F65F73" },
                ]}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="max-h-[150px] overflow-hidden overflow-y-auto scrollbar-thin scrollbar-track-neutral-100 scrollbar-thumb-[#70CFD6]">
              <BarChart
                data={[
                  { name: "test1", percentage: 23, color: "#64C18F" },
                  { name: "test2", percentage: 67, color: "#E2E2E8" },
                  {
                    name: "test3",
                    percentage: 5,
                    color: "rgba(250, 26, 93, 0.74)",
                  },
                  { name: "test4", percentage: 28, color: "#5BC8D0" },
                ]}
              />
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="flex justify-end">
          <div className="flex space-x-2">
            <button
              type="button"
              className="change-channel-btn change-channel-btn__prev"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="h-4 w-4 change-channel-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <button
              type="button"
              className="change-channel-btn change-channel-btn__next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="h-4 w-4 change-channel-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Response;
