import { instanceMockAdapter } from "../helpers/requestUtil";

instanceMockAdapter
  // eslint-disable-next-line
  .onGet(/\/api\/v1\/bar/g, { channel: /.*/, filter: /.*/ })
  .reply(async (req) => {
    let res = [];
    await fetch(`${process.env.PUBLIC_URL}/assets/bar.data.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // res = Object.entries(data).find(([key]) => key === req.params.filter);
        res = data;
        // if (res.length > 0) res = res[1][req.params.channel];
      })
      .catch((err) => {
        return [500, { error: err }];
      });

    return [200, [...res]];
  });
