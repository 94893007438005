import "./styles.css";

import React, { useEffect, useState } from "react";

import { ReactComponent as AscIcon } from "../../../assets/images/icons/asc-colorful.svg";
import { ReactComponent as DescIcon } from "../../../assets/images/icons/desc-colorful.svg";
import { Line } from "../../shared/progress/Progress";
import coachingServices from "../../../services/coaching.services";

const CoachingSummery = () => {
  const [dataSession, setDataSession] = useState({
    result: {
      totalAgentsCount: 0,
      coachedAgentsCount: 0,
      coachedAgentsPercentChange: 0,
      coachingSessionCount: 0,
      coachingSessionPercentChange: 0,
    },
  });

  const fetchDataSession = async () => {
    try {
      const res = await coachingServices.fetchCoachingSessions();
      setDataSession(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataSession();
  }, []);

  return (
    <div className="w-full">
      <div className="jibe-summery">
        <span className="jibe-summery-title">Coaching Summary</span>
        <div className="jibe-summery-content">
          <div className="jibe-summery-agent">
            <div className="jibe-summery-agent-detail-numeric">
              <div className="jibe-summery-agent-detail-changes">
                <span className="jibe-summery-agent-detail-changes-percent">
                  {(dataSession.result.totalAgentsCount > 0
                    ? (dataSession.result.coachedAgentsCount || 0 * 100) /
                      dataSession.result.totalAgentsCount
                    : 0
                  ).toFixed(2)}
                  %
                </span>
                <div className="jibe-summery-agent-detail-changes-analysis">
                  <span className="jibe-summery-agent-detail-changes-analysis-title">
                    Coached Agents
                  </span>
                  <span className="jibe-summery-agent-detail-changes-analysis-count">
                    {dataSession.result.coachedAgentsCount || 0}
                  </span>
                  {dataSession.result.coachedAgentsPercentChange > 0 ? (
                    <AscIcon className="jibe-summery-agent-detail-changes-analysis-icon" />
                  ) : (
                    <DescIcon className="jibe-summery-agent-detail-changes-analysis-icon" />
                  )}
                </div>
              </div>
              <div className="jibe-summery-agent-detail-total">
                <span className="jibe-summery-agent-detail-total-count">
                  {dataSession.result.totalAgentsCount || 0}
                </span>

                <span className="jibe-summery-agent-detail-total-title">
                  All Agents
                </span>
              </div>
            </div>
            <div className="jibe-summery-agent-detail-progress">
              <Line
                percent={
                  dataSession.result.totalAgentsCount > 0
                    ? (dataSession.result.coachedAgentsCount || 0 * 100) /
                      dataSession.result.totalAgentsCount
                    : 0
                }
              />
            </div>
          </div>
          <div className="jibe-summery-sessions">
            <span className="jibe-summery-sessions-count">
              {dataSession.result.coachingSessionCount | 0}
            </span>
            <div className="">
              <span className="jibe-summery-sessions-title">
                Coaching Sessions
              </span>

              <div className="jibe-summery-sessions-analysis">
                {dataSession.result.coachingSessionPercentChange > 0 ? (
                  <AscIcon className="jibe-summery-sessions-analysis-icon" />
                ) : (
                  <DescIcon className="jibe-summery-sessions-analysis-icon" />
                )}

                <span>{dataSession.result.coachingSessionPercentChange || 0}%</span>
                <span className="jibe-summery-sessions-analysis-range">
                  30d
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingSummery;
