import React, { useEffect, useRef, useState } from "react";

import Agents from "../../components/user-management/agents/agents";
import Tabs from "rc-tabs";
import Teams from "../../components/user-management/teams/teams";
import { useLocation } from "react-router-dom";

const UsersManagement = () => {
  const contentRef = useRef(null);
  const [current, setCurrent] = useState("teams");

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state?.tab) setCurrent(location.state?.tab);

    return () => {
      window.history.replaceState({}, document.title);
    };
  }, [location.state]);

  return (
    <div className="relative">
      <div className="absolute top-2 z-10">
        <div className="flex items-center gap-2 text-sm font-normal text-[#0091AE]">
          <div>
            <svg
              viewBox="0 0 20 20"
              fill="none"
              className="h-5 w-5 flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 16V14.3333C13.5 13.4493 13.1488 12.6014 12.5237 11.9763C11.8986 11.3512 11.0507 11 10.1667 11H4.33333C3.44928 11 2.60143 11.3512 1.97631 11.9763C1.35119 12.6014 1 13.4493 1 14.3333V16"
                stroke={`url(#active)`}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.3334 7.66667L16 9.33333L19.3334 6"
                stroke={`url(#active)`}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.25002 7.66667C9.09097 7.66667 10.5834 6.17428 10.5834 4.33333C10.5834 2.49238 9.09097 1 7.25002 1C5.40907 1 3.91669 2.49238 3.91669 4.33333C3.91669 6.17428 5.40907 7.66667 7.25002 7.66667Z"
                stroke={`url(#active)`}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="active"
                  x1="1.44681"
                  y1="1.66675"
                  x2="18.0341"
                  y2="2.16006"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2547D" />
                  <stop offset="1" stopColor="#FF7959" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
          <div className={`text-[#33475B]`}>Users Management</div>
        </div>
      </div>
      {/* <div className="absolute right-0 top-0 z-10 px-2">
        <button type="button" className="p-4 pb-6 pt-2">
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.74981 0C7.9596 0 6.24271 0.711159 4.97684 1.97703C3.71097 3.2429 2.99981 4.95979 2.99981 6.75C2.99981 10.1395 2.27451 12.2635 1.59139 13.5159C1.2488 14.144 0.912733 14.5604 0.6741 14.8123C0.554587 14.9385 0.459002 15.0239 0.398582 15.0742C0.368362 15.0994 0.346915 15.1158 0.335647 15.1242L0.327015 15.1305C0.0567889 15.3149 -0.0629886 15.6536 0.0319951 15.9673C0.127769 16.2837 0.419314 16.5 0.749814 16.5H18.7498C19.0803 16.5 19.3719 16.2837 19.4676 15.9673C19.5626 15.6536 19.4428 15.3149 19.1726 15.1305L19.164 15.1242C19.1527 15.1158 19.1313 15.0994 19.101 15.0742C19.0406 15.0239 18.945 14.9385 18.8255 14.8123C18.5869 14.5604 18.2508 14.144 17.9082 13.5159C17.2251 12.2635 16.4998 10.1395 16.4998 6.75C16.4998 4.95979 15.7887 3.2429 14.5228 1.97703C13.2569 0.711159 11.54 0 9.74981 0ZM16.5914 14.2341C16.7485 14.5221 16.9065 14.7765 17.06 15H2.43963C2.59311 14.7765 2.75116 14.5221 2.90824 14.2341C3.72512 12.7365 4.49981 10.3605 4.49981 6.75C4.49981 5.35761 5.05294 4.02226 6.0375 3.03769C7.02207 2.05312 8.35743 1.5 9.74981 1.5C11.1422 1.5 12.4776 2.05312 13.4621 3.03769C14.4467 4.02225 14.9998 5.35761 14.9998 6.75C14.9998 10.3605 15.7745 12.7365 16.5914 14.2341ZM8.66856 19.3737C8.46072 19.0154 8.00178 18.8934 7.64349 19.1012C7.28519 19.3091 7.16322 19.768 7.37106 20.1263C7.6128 20.5431 7.95978 20.889 8.37725 21.1294C8.79473 21.3699 9.26804 21.4965 9.74981 21.4965C10.2316 21.4965 10.7049 21.3699 11.1224 21.1294C11.5398 20.889 11.8868 20.5431 12.1286 20.1263C12.3364 19.768 12.2144 19.3091 11.8561 19.1012C11.4978 18.8934 11.0389 19.0154 10.8311 19.3737C10.7212 19.5631 10.5635 19.7203 10.3737 19.8296C10.1839 19.9389 9.9688 19.9965 9.74981 19.9965C9.53083 19.9965 9.31568 19.9389 9.12592 19.8296C8.93616 19.7203 8.77845 19.5631 8.66856 19.3737Z"
              fill="#32A6AE"
            />
          </svg>
        </button>
      </div> */}
      <Tabs
        ref={contentRef}
        prefixCls="ink-tabs"
        defaultActiveKey={current}
        activeKey={current}
        onChange={(key) => setCurrent(key)}
        className="ink-tabs-v2 !border-0"
        tabBarGutter={20}
        items={[
          {
            key: "agents",
            label: "Agents",
            children: <Agents />,
          },
          {
            key: "teams",
            label: "Teams",
            children: <Teams />,
          },
        ]}
      />
    </div>
  );
};

export default UsersManagement;
