import request from "../helpers/request";

function fetchOutliers(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(
      `/api/systemdashboardv3/outliers`,
      params
    );
}

const outliersServices = {
  fetchOutliers,
};

export default outliersServices;
