import React, { useEffect, useRef, useState } from "react";

import Highcharts from "highcharts";
import variablePie from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";

variablePie(Highcharts);
solidGauge(Highcharts);

const options = {
  chart: {
    type: "pie",
    height: 100,
    spacing: [0, 0, 0, 0],
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  title: {
    text: "",
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      shadow: false,
      center: ["50%", "50%"],
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: false,
        },
      },
      size: "110%",
      innerSize: "105%",
      borderColor: null,
      borderWidth: 3,
    },
  },
  series: [{}],
};

export default function CircleChart({ data = 40, color, name }) {
  const chartRef = useRef(null);

  useEffect(() => {
    chartRef.current.chart.update({
      subtitle: {
        text: `<div class="flex items-center gap-0.5">
        <span class="">${data.toFixed(0)}</span>
        <span class="text-xs">%</span>
        </div>`,
        align: "center",
        verticalAlign: "middle",
        style: {
          fontSize: "1.5rem",
          fontWeight: "normal",
          textAlign: "center",
          color: "#474E55",
        },
        x: 0,
        y: 26,
        useHTML: true,
      },
    });

    chartRef.current.chart.series[0].update({
      innerSize: "80%",
      data: [
        {
          name: name,
          y: 100 - Number(data),
          color: "#E6F7F8",
        },
        {
          name: name,
          y: Number(data),
          color: color,
        },
      ],
    });
  }, [data]);

  return (
    <div className="overflow-visiable">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
}
