import React, { useEffect, useState } from "react";

import { Avatar } from "@agney/react-avatar";

const MAX_USER_SHOW = 3;

const AgentTeams = ({ teamsList = [] }) => {
  return (
    <div className="flex items-center -space-x-2">
      {teamsList.length > 0 && (
        <>
          {teamsList.slice(0, MAX_USER_SHOW).map((team) => (
            <div
              key={team.name}
              className="inline-flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-full border-2 border-white"
            >
              <Avatar
                text={team.name || ""}
                alt={team.name}
                backgrounds={[
                  "#f28482",
                  "#84a59d",
                  "#b08968",
                  "#eb5e28",
                  "#b8c0ff",
                ]}
                src=""
                shape="circle"
                className="text-xs !font-normal text-white"
                textColor={"#fff"}
                textProcessor={(text) =>
                  text
                    .match(/(^\S\S?|\b\S)?/g)
                    .join("")
                    .match(/(^\S|\S$)?/g)
                    .join("")
                    .toUpperCase()
                }
                // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
              />
            </div>
          ))}
          {teamsList.length > MAX_USER_SHOW && (
            <div className="inline-flex h-[40px] w-[40px] items-center justify-center rounded-full border-2 border-white bg-gray-200 align-middle text-sm font-normal text-[#425B76] shadow-sm transition-all focus:outline-none">
              +{teamsList.length - MAX_USER_SHOW}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AgentTeams;
