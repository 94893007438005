const dataTotal = {
  ASAT: {
    showInLegend: false,
    name: "ASAT",
    label: "ASAT",
    yAxis: 0,
    data: [],
    states: {
      hover: {
        enabled: false,
      },
    },
    color: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [
        [0, "#43F1DF"],
        [0.5, "#90F9ED"],
        [1, "#24DECA"],
      ],
    },
  },
  RES: {
    showInLegend: false,
    name: "RES",
    label: "RES",
    yAxis: 0,
    data: [],
    states: {
      hover: {
        enabled: false,
      },
    },
    color: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [
        [0, "#73E0E8"],
        [0.5, "#AFEDF1"],
        [1, "#46AFB6"],
      ],
    },
  },
  CSAT: {
    showInLegend: false,
    name: "CSAT",
    label: "CSAT",
    yAxis: 0,
    data: [],
    states: {
      hover: {
        enabled: false,
      },
    },
    color: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [
        [0, "#FFB97B"],
        // [0.5, Highcharts.Color("#d6d157").brighten(0.57).get("rgba")],
        [0.5, "#d6d157"],
        [1, "#FEA150"],
      ],
    },
  },
  JibeSession: {
    showInLegend: false,
    name: "JibeSession",
    label: "Jibe Sessions",
    type: "spline",
    yAxis: 1,
    data: [],
    states: {
      hover: {
        enabled: false,
      },
    },
    marker: {
      symbol: "circle",
    },
    color: "#E6DDF8",
  },
};

const data = {
  ASAT: {
    showInLegend: false,
    name: "ASAT",
    label: "ASAT",
    yAxis: 0,
    data: [],
    states: {
      hover: {
        enabled: false,
      },
    },
    color: "#AE83E2",
  },
  RES: {
    showInLegend: false,
    name: "RES",
    label: "RES",
    yAxis: 0,
    data: [],
    states: {
      hover: {
        enabled: false,
      },
    },
    color: "#49C2CB",
  },
  CSAT: {
    showInLegend: false,
    name: "CSAT",
    label: "CSAT",
    yAxis: 0,
    data: [],
    states: {
      hover: {
        enabled: false,
      },
    },
    color: "#FF9538",
  },
  JibeSession: {
    showInLegend: false,
    name: "JibeSession",
    label: "Jibe Sessions",
    type: "spline",
    yAxis: 1,
    data: [],
    marker: {
      symbol: "circle",
    },
    states: {
      hover: {
        enabled: false,
      },
    },
    color: "#E6DDF8",
  },
};

export const options = {
  accessibility: {
    enabled: false,
  },
  chart: {
    type: "column",
    style: {
      fontFamily: "Roboto",
    },
    marginRight: 0,
    marginLeft: 0,
    spacingRight: 55,
    spacingLeft: 55,
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    align: "left",
    verticalAlign: "top",
  },
  xAxis: {
    type: "category",
    lineColor: "transparent",
    labels: {
      events: {
        click: function () {
          // TODO: onClick Team Label
          console.log("onClick Team Label", this);
        },
      },
      style: {
        fontFamily: "Roboto",
        color: "#33475B",
        fontWeight: 400,
        fontSize: "16px",
        fontStyle: "normal",
        lineHeight: "19px",
      },
    },
    title: {
      text: "",
    },
    scrollbar: {
      enabled: true,
    },
    offset: 10,
    min: 0,
    max: 1,
  },
  yAxis: [
    {
      name: "A0",
      min: 0,
      max: 100,
      gridLineWidth: 0,
      title: {
        text: "",
      },
      labels: {
        format: "{text} %",
      },
    },
    {
      name: "A1",
      min: 0,
      max: 1500,
      opposite: true,
      gridLineWidth: 0,
      title: {
        text: "",
      },
      labels: {
        format: "{text}",
      },
    },
  ],
  tooltip: {
    headerFormat: `<table style="color:#33475B">`,
    pointFormatter: function () {
      let color = "#eeeeee";

      try {
        color = this.color?.stops[0][1];
      } catch (error) {}

      return (
        '<tr><td><div style="display:flex;align-items:center;padding:4px 0;"><span style="display:inline-block;background-color:' +
        (typeof this.color == "object" ? color : this.color) +
        ';width:7px;height:28px;border-radius:0 7px 7px 0"></span><span style="color:#32A6AE;padding-left:8px;font-size:12px">' +
        this.series.name +
        '</span></div></td><td style="padding-left:16px;font-size:14px">' +
        this.y +
        (this.series.name !== "JibeSession" ? "%" : "") +
        "</td></tr>"
      );
    },
    footerFormat: "</table>",
    positioner: function (t1, t2, point) {
      return {
        x:
          point.plotX + t1 > this.chart.plotWidth
            ? point.plotX - t1 / 2
            : point.plotX,
        y: this.chart.plotHeight - t2,
      };
    },
    // hideDelay: 200000,
    borderWidth: 0,
    padding: 0,
    margin: 0,
    borderRadius: 16,
    backgroundColor: "white",
    zIndex: -1,
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    series: {
      connectNulls: true,
      borderRadiusTopLeft: "4.5rem",
      borderRadiusTopRight: "4.5rem",
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          enabled: false,
        },
      },
      point: {
        events: {
          mouseOver: function () {
            var point = this,
              chart = point.series.chart,
              allSeries = chart.series,
              category = point.category;
            allSeries.forEach((series) => {
              series.points.forEach((point) => {
                if (point) {
                  if (point.category === category) {
                    point.update(
                      {
                        opacity: 1,
                      },
                      false
                    );
                  } else {
                    point.update(
                      {
                        opacity: 0.2,
                      },
                      false
                    );
                  }
                }
              });
            });
            chart.redraw();
          },
          mouseOut: function () {
            var point = this,
              chart = point.series.chart,
              allSeries = chart.series,
              // eslint-disable-next-line
              category = point.category;
            allSeries.forEach((series) => {
              series.points.forEach((point) => {
                point.update(
                  {
                    opacity: 1,
                  },
                  false
                );
              });
            });
            chart.redraw();
          },
        },
      },
    },
    column: {
      dataLabels: {
        enabled: true,
        format: "{y}<span style='color:#32A6AE'>%</span>",
        useHTML: true,
      },
    },
  },
  series: [],
  responsive: {
    rules: [
      {
        condition: {
          minWidth: 1081,
        },
        chartOptions: {
          xAxis: {
            max: 4,
          },
        },
      },
      {
        condition: {
          minWidth: 720,
          maxWidth: 1080,
        },
        chartOptions: {
          xAxis: {
            max: 3,
          },
        },
      },
    ],
  },
};

export const normalized = (items) => {
  if (items && items.length > 0) {
    let tmpColumns = [];
    let tmpItems = {};

    items[0].values.forEach((key) => {
      tmpColumns.push({ ...dataTotal[key.name] }, { ...data[key.name] });
      tmpItems[key.name] = null;
    });

    return items.reduce(
      (result, item, index) => {
        const values = [...item.values].reduce(
          (result, value) => {
            return { ...result, [value.name]: Number(value.value) };
          },
          { ...tmpItems }
        );

        Object.entries(values).forEach(([key, value]) => {
          if (item.name === "All Teams") {
            const index = result.findIndex((r) => r.name === key);
            if (index > -1)
              result[index]["data"] = [
                ...result[index]["data"],
                [item.name, value],
              ];
          } else {
            const index = result.findLastIndex((r) => r.name === key);
            if (index > -1)
              result[index]["data"] = [
                ...result[index]["data"],
                [item.name, value],
              ];
          }
        });

        return result;
      },
      [...tmpColumns]
    );
  } else {
    return [];
  }
};
