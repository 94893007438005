//import 'bootstrap/dist/css/bootstrap.css';
import "./mocks";
import "./helpers/bootstrap";
import "./helpers/prototypes";

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const rootElement = document.getElementById('root');
//const root = createRoot(rootElement);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(  
    <React.StrictMode>
        <Provider store={store}>
            {/* <BrowserRouter> */}
            <App />
            {/* </BrowserRouter> */}
        </Provider>
    </React.StrictMode>
);

//    <BrowserRouter basename={baseUrl}>
//  </BrowserRouter>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
