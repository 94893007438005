import React, { useEffect, useState } from "react";

import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import Dialog from "rc-dialog";
import dayjs from "dayjs";

const DateRangePicker = ({
  value = {
    from: null,
    to: null,
  },
  onChange,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    setSelectedDayRange(value);
  }, [value]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClearDates = () => {
    setSelectedDayRange({
      from: null,
      to: null,
    });
  };

  const selectLastWeek = (numberWeek = 1) => {
    const lastWeek = dayjs().subtract(numberWeek, "week");
    const today = dayjs();

    setSelectedDayRange({
      from: {
        year: lastWeek.year(),
        month: lastWeek.month() + 1,
        day: lastWeek.date(),
      },
      to: {
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
      },
    });
  };

  const selectLastMonth = (numberMonth = 1) => {
    const lastMonth = dayjs().subtract(numberMonth, "month");
    const today = dayjs();

    setSelectedDayRange({
      from: {
        year: lastMonth.year(),
        month: lastMonth.month() + 1,
        day: lastMonth.date(),
      },
      to: {
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
      },
    });
  };

  return (
    <>
      {selectedDayRange.from || selectedDayRange.to ? (
        <>
          <div
            className={`mr-2 mt-2 inline-flex flex-wrap items-center rounded-full border border-cyan-300 px-4 pb-2`}
          >
            <button
              type="button"
              className="mr-2 mt-2 flex items-center truncate text-sm font-normal text-orange-400"
              onClick={showModal}
            >
              <span>Add Date</span>
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 flex-shrink-0 font-black text-orange-400"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>

            {selectedDayRange.from && (
              <div className="mr-3 mt-2 flex items-center space-x-2 truncate last:mr-0">
                <span>{`${selectedDayRange.from.day}/${selectedDayRange.from.month}/${selectedDayRange.from.year}`}</span>
                <button
                  onClick={() =>
                    setSelectedDayRange({
                      ...selectedDayRange,
                      from: null,
                      to: null,
                    })
                  }
                  type="button"
                  className=""
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="inline-block h-6 w-6 cursor-pointer text-cyan-300"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                </button>
              </div>
            )}

            {selectedDayRange.to && (
              <>
                <div className="mr-3 mt-2 flex items-center space-x-2 truncate last:mr-0">
                  <span>{`${selectedDayRange.to.day}/${selectedDayRange.to.month}/${selectedDayRange.from.year}`}</span>
                  <button
                    onClick={() =>
                      setSelectedDayRange({
                        ...selectedDayRange,
                        to: null,
                      })
                    }
                    type="button"
                    className=""
                  >
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="inline-block h-6 w-6 cursor-pointer text-cyan-300"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="15" y1="9" x2="9" y2="15"></line>
                      <line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <button
            type="button"
            onClick={showModal}
            className="relative mr-2 mt-2 inline-block overflow-hidden truncate rounded-full bg-purple-100 px-5 py-2.5 text-sm font-semibold"
          >
            <svg
              width="31"
              height="40"
              viewBox="0 0 31 40"
              className="absolute left-0 top-0"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.7"
                d="M0 20C0 8.9543 8.9543 0 20 0H21C4 20.5 31 40 31 40H20C8.9543 40 0 31.0457 0 20Z"
                fill="url(#paint0_linear_694_1301)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_694_1301"
                  x1="20"
                  y1="-1.88392e-06"
                  x2="33"
                  y2="44.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D9CAEB" />
                  <stop offset="1" stopColor="#F1EAFA" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 inline-block h-5 w-5 text-cyan-500"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="16" y1="2" x2="16" y2="6"></line>
              <line x1="8" y1="2" x2="8" y2="6"></line>
              <line x1="3" y1="10" x2="21" y2="10"></line>
            </svg>
            <span>Customize Date</span>
          </button>
        </>
      )}

      <Dialog
        visible={isModalOpen}
        className="!max-w-xl"
        onClose={handleCancel}
        closeIcon={() => <></>}
      >
        <div className="flex">
          <div
            className={`relative z-0 ${
              selectedDayRange.to && selectedDayRange.from && "multi-selected"
            }`}
          >
            <Calendar
              value={selectedDayRange}
              setSelectedDayRange={setSelectedDayRange}
              colorPrimary="#00BBA7"
              colorPrimaryLight="#C0EAED"
              // className="shadow-0"
              calendarClassName="custom-calendar"
              renderFooter={() => {
                return (
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex flex-col space-y-1 text-[#33475B]">
                      <table>
                        <tr>
                          <td className="text-right">
                            <span className="font-[400] text-cyan-400">
                              From:
                            </span>
                          </td>
                          <td className="!pl-2">
                            <span className="font-medium">
                              {selectedDayRange.from &&
                                dayjs(
                                  `${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`
                                ).format("DD MMMM YYYY")}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right">
                            <span className="font-[400] text-cyan-400">
                              To:
                            </span>
                          </td>
                          <td className="!pl-2">
                            <span className="font-medium">
                              {selectedDayRange.to &&
                                dayjs(
                                  `${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`
                                ).format("DD MMMM YYYY")}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="">
                      <button
                        type="button"
                        onClick={() => {
                          onChange(selectedDayRange);
                          handleCancel();
                        }}
                        disabled={!selectedDayRange.from}
                        className={`inline-block items-center ${
                          selectedDayRange.from
                            ? "bg-orange-400 text-white"
                            : "bg-[#F2EAFA] text-[#33475B] opacity-80"
                        } rounded-full !px-4 !py-2 text-sm `}
                      >
                        <span>Done</span>
                      </button>
                    </div>
                  </div>
                );
              }}
            />

            <button
              type="button"
              onClick={handleClearDates}
              className="absolute right-0 top-1.5 z-10 text-sm text-cyan-500 hover:text-cyan-500"
            >
              Clear
            </button>
          </div>

          <div className="flex flex-grow flex-col items-center justify-center space-y-3 px-4">
            <button
              type="button"
              onClick={() => selectLastWeek(1)}
              className="w-full max-w-[150px] rounded-full bg-[#F2EAFA] px-5 py-2 text-sm text-[#425B76]"
            >
              Last week
            </button>

            <button
              type="button"
              onClick={() => selectLastWeek(2)}
              className="w-full max-w-[150px] rounded-full bg-[#F2EAFA] px-5 py-2 text-sm text-[#425B76]"
            >
              Last two weeks
            </button>

            <button
              type="button"
              onClick={() => selectLastMonth(1)}
              className="w-full max-w-[150px] rounded-full bg-[#F2EAFA] px-5 py-2 text-sm text-[#425B76]"
            >
              Last month
            </button>

            <button
              type="button"
              onClick={() => selectLastMonth(3)}
              className="w-full max-w-[150px] rounded-full bg-[#F2EAFA] px-5 py-2 text-sm text-[#425B76]"
            >
              Last 3 months
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DateRangePicker;
