import React, { useEffect, useState } from "react";

import { Avatar } from "@agney/react-avatar";
import TeamUpdate from "./teamUpdate";

const MAX_USER_SHOW = 3;

const TeamAgents = ({ team, users }) => {
  const [drawers, setDrawers] = useState({
    view: false,
    update: false,
    create: false,
  });

  const openDrawer = (type) => {
    let drawersModified = { ...drawers };
    Object.keys(drawersModified).forEach(
      // eslint-disable-next-line
      (key) => {
        const value = drawersModified[key];
        drawersModified = {
          ...drawersModified,
          [key]: key === type ? !value : false,
        };
      }
    );
    setDrawers(drawersModified);
  };

  const closeDrawer = (type) => {
    let drawersModified = { ...drawers };
    Object.keys(drawersModified).forEach(
      // eslint-disable-next-line
      (key) => {
        const value = drawersModified[key];
        drawersModified = {
          ...drawersModified,
          [key]: key === type ? !value : value,
        };
      }
    );
    setDrawers(drawersModified);
  };

  return (
    <>
      <div className="flex items-center -space-x-2">
        {users.length > 0 && (
          <>
            {users.slice(0, MAX_USER_SHOW).map((user) => (
              <div
                key={user.userName}
                className="inline-flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-full border-2 border-white"
              >
                <Avatar
                  text={user.name || ""}
                  alt={user.userName}
                  backgrounds={[
                    "#f28482",
                    "#84a59d",
                    "#b08968",
                    "#eb5e28",
                    "#b8c0ff",
                  ]}
                  src=""
                  shape="circle"
                  className="text-xs !font-normal text-white"
                  textColor={"#fff"}
                  textProcessor={(text) =>
                    text
                      .match(/(^\S\S?|\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()
                  }
                  // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                />
              </div>
            ))}
            {users.length > MAX_USER_SHOW && (
              <div className="inline-flex h-[40px] w-[40px] items-center justify-center rounded-full border-2 border-white bg-gray-200 align-middle text-sm font-normal text-[#425B76] shadow-sm transition-all focus:outline-none">
                +{users.length - MAX_USER_SHOW}
              </div>
            )}

            <TeamUpdate.TeamUpdateBtn
              toggleDrawer={() => {
                openDrawer("update");
              }}
              className="inline-flex h-[40px] w-[40px] items-center justify-center rounded-full border-2 border-white bg-[#FF9538] align-middle text-sm font-normal text-white shadow-sm transition-all focus:outline-none"
            >
              <svg
                className="h-4 w-4"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17V9M9 9V1M9 9H17M9 9H1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </TeamUpdate.TeamUpdateBtn>
          </>
        )}
        <TeamUpdate.TeamUpdateBox
          team={team}
          isOpen={drawers.update}
          handleClose={() => closeDrawer("update")}
        />
      </div>
    </>
  );
};

export default TeamAgents;
