import "./styles.css";

import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { filter, flatMap, get, isEqual, orderBy } from "lodash";

import Empty from "../empty";
import Pagination from "../paginationScope";
import TableHeaderColumn from "../table/TableHeaderColumn";
import TableRow from "../table/TableRow";

const PAGE_SIZE = parseInt(process.env.REACT_APP_PAGE_SIZE || "10", 10);

// export interface ISelect {
//   label: string;
//   value: any;
// }

// export interface IColumn {
//   name: string;
//   key: string;
//   align?: "left" | "right" | "center";
//   className?: string;
//   headerClassName?: string;
//   colSpan?: number;
//   width?: string | number;
//   render?: (
//     text: string,
//     record: any,
//     index: number,
//     page: number
//   ) => ReactNode;
// }

// // eslint-disable-next-line
// interface IParams {
//   _filter?: any;
//   _sort?: string;
//   _populate?: any;
//   _limit?: number | string;
//   _start: number;
//   _page?: number;
// }

// interface IPagination {
//   pageSize?: number;
//   maxPages?: number;
// }
// interface IProps {
//   // api?: undefined | null | string;
//   pagination?: undefined | null | IPagination;
//   totalItems: number;
//   rowKey?: string;
//   columns: IColumn[];
//   dataSet: any[];
//   loading?: boolean;
//   isDroppable?: boolean;
//   setData?: (d: any) => void;
//   setHasDroppable?: (d: any) => void;
// }

function deepSearch(object, key, predicate) {
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true)
    return object;

  for (let i = 0; i < Object.keys(object).length; i++) {
    let value = object[Object.keys(object)[i]];
    if (typeof value === "object" && value != null) {
      let o = deepSearch(object[Object.keys(object)[i]], key, predicate);
      if (o != null) return o;
    }
  }
  return null;
}

const Table = (props) => {
  const {
    dataSet,
    showFooter = false,
    pagination,
    columns,
    rowKey,
    rowHeight,
    loading = false,
    totalItems = 0,
    expandable,
    filters = {},
  } = props;

  // eslint-disable-next-line
  const [filteredDataSet, setFilteredDataSet] = useState([...dataSet]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(pagination?.pageSize || PAGE_SIZE);
  const [orders, setOrders] = useState({});

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    setPage(1);
  }, [orders]);

  useEffect(() => {
    let keys = Object.keys(filters);

    if (keys.length > 0) {
      let filtered = filter(dataSet, function (o) {
        let flg = Array.from({ length: keys.length }, (_, i) => false);

        keys.forEach(function (filterKey, index) {
          let searchFilterValue = filters[filterKey];
          if (filterKey.includes(".")) {
            if (filterKey.includes("[]")) {
              let rowKeys = filterKey.split(".");
              rowKeys = rowKeys.map((key) => key.replaceAll(/\[\]/gm, ""));
              let rowValues = [];

              for (let i = 0; i < rowKeys.length; i++) {
                if (i === 0) {
                  rowValues = o[rowKeys[i]];
                } else if (Array.isArray(o[rowKeys[i - 1]])) {
                  rowValues = rowValues.map((v) => v[rowKeys[i]]);
                } else {
                  rowValues = rowValues[rowKeys[i]];
                }
              }

              flg[index] =
                rowValues &&
                rowValues.length > 0 &&
                rowValues.some((r) => searchFilterValue.includes(r));
            } else flg[index] = searchFilterValue.includes(get(o, filterKey));
          } else {
            if (typeof searchFilterValue == "boolean") {
              console.log(flg, o[filterKey] + "" == searchFilterValue + "");
              flg[index] = o[filterKey] + "" == searchFilterValue + "";
            } else flg[index] = searchFilterValue.includes(o[filterKey]);
          }
        });

        if (flg.every((v) => !!v)) return o;
      });

      setFilteredDataSet(filtered);
    } else setFilteredDataSet(dataSet);
  }, [filters]);

  const handleSortOrder = (key, order) => {
    if (!isEqual(orders, { ...orders, [key]: order })) {
      const newOrderList = { ...orders, [key]: order };
      setOrders(newOrderList);
    }
  };

  return (
    <>
      <div
        className="scrollbar-rounded-full relative h-full w-full overflow-hidden overflow-x-scroll scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-700 scrollbar-w-2"
        style={{
          minHeight:
            filteredDataSet && filteredDataSet.length > 0
              ? `calc((${rowHeight || 4}px * ${perPage}) + ${
                  perPage - (totalItems % perPage)
                }px + 58px)`
              : "auto",
        }}
      >
        <table className="w-full table-auto">
          <thead className="">
            <tr className="">
              {columns.map((column, i) => {
                return (
                  <TableHeaderColumn
                    column={column}
                    filter={{
                      query: filters[column.key],
                    }}
                    setPage={setPage}
                    isFirst={i === 0}
                    isLast={i === columns.length - 1 && !expandable}
                    key={i}
                    order={orders && orders[`${column.key}`]}
                    handleSortOrder={handleSortOrder}
                  />
                );
              })}
              {expandable && expandable.rowExpandable && (
                <TableHeaderColumn
                  isLast={true}
                  isFirst={false}
                  column={{ isHidden: false }}
                />
              )}
            </tr>
          </thead>
          <tbody className="max-h-screen overflow-hidden overflow-y-scroll bg-white">
            {loading ? (
              <tr>
                <td colSpan={columns.length + 1}>
                  <div className="flex items-center justify-center px-4 py-10">
                    <img
                      src="/assets/images/Zac.gif"
                      alt="loading"
                      className="w-80"
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {filteredDataSet &&
                columns &&
                filteredDataSet.length > 0 &&
                columns.length > 0 ? (
                  orderBy(
                    filteredDataSet,
                    [...(Object.keys(orders || {}) || [])].map((x) => (y) => {
                      return y && x && y[x] ? get(y, x).toLowerCase() : y[x];
                    }),
                    [...(Object.values(orders || {}) || [])].map((x) =>
                      x ? x.toLowerCase() : x
                    )
                  )
                    .slice((page - 1) * perPage, page * perPage)
                    .map((data, i) => (
                      <TableRow
                        data={data}
                        columns={columns}
                        expandable={expandable}
                        index={i}
                        rowKey={rowKey}
                        key={rowKey ? data[rowKey] : i}
                      />

                      // eslint-disable-next-line
                      // <tr
                      //   className="border-b border-[#98DDE1] transition-all"
                      //   key={rowKey ? data[rowKey] : i}
                      // >
                      //   {columns?.map((column, j) => (
                      //     <td
                      //       className={`whitespace-nowrap px-3 py-3 text-sm text-gray-900 ${
                      //         column.className ? column.className : ""
                      //       }`}
                      //       // eslint-disable-next-line
                      //       key={j}
                      //     >
                      //       {column && (
                      //         <>
                      //           {column.render
                      //             ? column.render(
                      //                 data[column.key],
                      //                 data,
                      //                 i,
                      //                 page
                      //               )
                      //             : data[column.key]}
                      //         </>
                      //       )}
                      //     </td>
                      //   ))}
                      // </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={columns.length + 1}>
                      <div className="flex items-center justify-center px-4 py-10">
                        <Empty />
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      {(showFooter || pagination) && (
        <div className="flex justify-between bg-[#F8F5FD] px-8 py-4 lg:items-center">
          <div className="flex gap-14 lg:items-center">
            <div className="flex items-center gap-1">
              <span>Records :</span>
              <span className="font-semibold">{totalItems || 0}</span>
            </div>

            <div className="flex items-center gap-2">
              <span>Show in each page:</span>
              <Select
                unstyled
                menuPlacement="top"
                value={[
                  { label: "10", value: 10 },
                  { label: "20", value: 20 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ].find((x) => x.value === perPage)}
                onChange={(v) => setPerPage(v.value)}
                options={[
                  { label: "10", value: 10 },
                  { label: "20", value: 20 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                isSearchable={false}
                isClearable={false}
                components={{
                  ...components,
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-1 h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </>
                  ),
                }}
                className="pagination-select-container"
                classNamePrefix="pagination-select"
              />
            </div>
          </div>

          {!loading && totalItems && totalItems > perPage ? (
            <div className="">
              <Pagination
                totalItems={totalItems}
                currentPage={page}
                setPage={setPage}
                pageSize={perPage}
                maxPages={3}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

Table.defaultProps = {
  pagination: null,
  // api: null,
};

export default Table;
