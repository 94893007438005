import React, { forwardRef } from "react";
import Tooltip from "rc-tooltip";
import { v4 as uuid } from "uuid";
import "rc-tooltip/assets/bootstrap.css";

const Table = forwardRef(
  ({ data: dataset, columns, selected, dateRange, loading }, ref) => {
    return (
      <>
        <div className="table-responsive">
          <table
            id="datatable"
            className="table text-sm"
            columnpadding="0"
            cellPadding="0"
            cellSpacing="0"
            ref={ref}
          >
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th
                    className={index === 0 ? "text-semibold text-left" : ""}
                    key={column.key}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={columns.length}>
                    <div
                      className="flex items-center justify-center px-4 py-10"
                      style={{
                        // minHeight: `calc(${rowHeight || 4}px * ${perPage})`,
                        minHeight: `90px`,
                      }}
                    >
                      <img
                        src="/assets/images/Zac.gif"
                        alt="loading"
                        className="w-80"
                      />
                    </div>
                  </td>
                </tr>
              ) : dataset && dataset.length > 0 ? (
                <>
                  {dataset.map((item) => {
                    return (
                      <tr
                        key={item.id + "_" + uuid()}
                        data-id={item.id}
                        className={`${selected == item.id ? "selected" : ""}`}
                      >
                        <td>
                          <Tooltip
                            overlayClassName="lg:hidden"
                            trigger={["click", "hover"]}
                            overlay={<span>{item.name}</span>}
                          >
                            <div className="flex items-center justify-between gap-1">
                              <span className="reason-title">{item.name}</span>
                            </div>
                          </Tooltip>
                        </td>
                        <td className="text-center">
                          <div className="flex items-center gap-2">
                            <span className="font-semibold">
                              {(item.currentValue + item.valueChanged).toFixed(
                                2
                              )}
                              %
                            </span>
                            <Balcance value={5} postfix="%" status="ERROR" />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="flex max-w-[80px] items-center justify-between gap-1">
                            <span className="font-semibold">
                              {(item.currentValue + item.valueChanged).toFixed(
                                2
                              )}
                              %
                            </span>
                            <Balcance value={5} postfix="%" status="SUCCESS" />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="flex max-w-[80px] items-center justify-between gap-1">
                            <span className="font-semibold">
                              {item.valueChanged.toFixed(2)}%
                            </span>
                            <Balcance value={5} postfix="%" status="SUCCESS" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={columns.length - 1}></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
);

export default Table;

const Balcance = ({ value, postfix = "%", status = "DEFAULT" }) => {
  return (
    <div
      className={`rounded px-1.5 py-1 text-[#F2F6F9] ${
        status === "ERROR" ? "bg-[#F9686B]" : ""
      }${status === "SUCCESS" ? "bg-[#0DB6A9]" : ""}`}
    >
      <span>{value}</span>
      <span>{postfix}</span>
    </div>
  );
};
