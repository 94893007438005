import * as FileSaver from "file-saver";

import React, { useEffect } from "react";

import { useScreenshot } from "use-react-screenshot";

const Screenshot = ({ screenShotRef }) => {
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(screenShotRef.current);

  useEffect(() => {
    if (image) FileSaver.saveAs(image, "chart.png");
  }, [image]);

  return (
    <>
      <button type="button" className="text-cyan-400" onClick={getImage}>
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-5 h-5 text-cyan-500 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
          <circle cx="12" cy="13" r="4"></circle>
        </svg>
      </button>
    </>
  );
};

export default Screenshot;
