import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import React from "react";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const headers = [["No.", "Name", "ASAT (%)", "RES (%)", "CSAT (%)", "Jibe Sessions"]];

const Export = ({ dataset = [] }) => {
  function exportToCSV() {
    const sheetData = dataset.map((item, i) => {
      const { values, id, ...slug } = item;
      const d = [...values].reduce(
        (result, value) => {
          return { ...result, [value.name]: Number(value.value) };
        },
        { ASAT: 0, RES: 0, CSAT: 0, JibeSession: 0 }
      );
      return {
        "No.": i + 1,
        ...slug,
        ...d,
      };
    });

    const ws = XLSX.utils.aoa_to_sheet(headers);

    XLSX.utils.sheet_add_json(ws, sheetData, {
      origin: "A2",
      skipHeader: true,
    });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `team-comparison-${new Date().getTime()}` + fileExtension
    );
  }

  return (
    <>
      <button type="button" className="text-cyan-400" onClick={exportToCSV}>
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-5 h-5 text-cyan-500 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="7 10 12 15 17 10"></polyline>
          <line x1="12" y1="15" x2="12" y2="3"></line>
        </svg>
      </button>
    </>
  );
};

export default Export;
