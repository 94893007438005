import "react-modern-drawer/dist/index.css";

import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";

import Drawer from "react-modern-drawer";
import Input from "../../../shared/form/input";
import { teamCreate } from "../../teams/teamSlice";
import userManagementServices from "../../../../services/userManagement.services";
import { v4 as uuid } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar } from "@agney/react-avatar";

const TeamCreate = ({}) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { users: usersList } = useSelector((state) => state.teams);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(),
        avatar: yup.mixed().required(),
        users: yup
          .array()
          .of(
            yup.object({
              id: yup.string().required(),
            })
          )
          .min(1),
      })
    ),
    defaultValues: {
      users: [],
    },
  });

  const [selectedUsers, name] = watch(["users", "name"]);

  const onSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    try {
      const { data } = await userManagementServices.createTeam({
        memberIds: values.users.map((t) => Number(t.id)),
        Name: values.name,
      });
      dispatch(teamCreate({ ...values, id: data.result, isEnabled: true }));
      toggleDrawer();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeMember = (username) => {
    setValue(
      "users",
      [...(selectedUsers || [])].filter((s) => s.userName !== username)
    );
  };

  return (
    <>
      <button
        type="button"
        onClick={toggleDrawer}
        className={`relative flex items-center space-x-1 overflow-hidden rounded-full bg-[#FF9538] px-5 py-2 text-white before:absolute before:bottom-0 before:left-0 before:z-[0] before:h-full before:w-8 before:bg-[url('../assets/images/patterns/btn-inner-shadow.svg')] before:content-['']`}
      >
        <span className="relative z-10">Add Team</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </button>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="max-h-screen overflow-hidden overflow-y-auto rounded-l-lg !bg-[#FDF9FB] pb-5"
        size={380}
        enableOverlay={true}
        overlayOpacity={0.1}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="min-h-[101px] w-full bg-gradient-to-r from-[rgba(0,0,0,0)] via-[#FF7700] to-[rgba(0,0,0,0)]">
            <div
              className="relative flex min-h-[100px] w-full flex-col items-center justify-center bg-white"
              style={{
                backgroundImage:
                  "url(/assets/images/patterns/drawer-header-pattern.png)",
              }}
            >
              <div className="absolute left-0 top-0 flex w-full justify-between px-5 py-5">
                <div className="">
                  <button type="button" onClick={toggleDrawer}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 12H5"
                        stroke="#32A6AE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 19L5 12L12 5"
                        stroke="#32A6AE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-col space-y-3">
                  <button type="submit" disabled={loading}>
                    {loading ? (
                      <>
                        <svg
                          width="20"
                          height="20"
                          xmlns="http://www.w3.org/2000/svg"
                          className="animate-spin text-[#32A6AE]"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={1}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 6L9 17L4 12"
                            stroke="#32A6AE"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </div>

              <div
                className={`absolute top-[2rem] mx-auto my-0 flex h-[132px] w-[132px] items-center justify-center rounded-full bg-white bg-gradient-to-tr p-0.5 ${
                  errors.avatar
                    ? "from-red-500 to-red-700"
                    : "to-[rgba(255,119,0, 0.7)] from-[#FF7700]"
                }`}
              >
                <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white p-2">
                  <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
                    {/* <img src="" className="absolute object-cover " alt="" /> */}
                    <Avatar
                      text={name || ""}
                      backgrounds={[
                        "#f28482",
                        "#84a59d",
                        "#b08968",
                        "#eb5e28",
                        "#b8c0ff",
                      ]}
                      src=""
                      shape="circle"
                      className="absolute object-cover text-3xl !font-normal text-white"
                      textColor={"#fff"}
                      textProcessor={(text) =>
                        text
                          .match(/(^\S\S?|\b\S)?/g)
                          .join("")
                          .match(/(^\S|\S$)?/g)
                          .join("")
                          .toUpperCase()
                      }
                      // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                    />
                  </div>

                  <div className="absolute bottom-0 right-0 h-10 w-10 rounded-full bg-white p-1">
                    <input
                      type="file"
                      {...register("avatar")}
                      id="avatar"
                      className="hidden"
                    />
                    <label
                      htmlFor="avatar"
                      className="flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[linear-gradient(91.89deg,#32A6AE_-6.8%,#00BBA7_100.34%)]"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 1.62132C11.8978 1.2235 12.4374 1 13 1C13.5626 1 14.1022 1.2235 14.5 1.62132C14.8978 2.01915 15.1213 2.55871 15.1213 3.12132C15.1213 3.68393 14.8978 4.2235 14.5 4.62132L5 14.1213L1 15.1213L2 11.1213L11.5 1.62132Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </label>
                  </div>

                  <span>{errors?.avatar?.message}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[68px] space-y-6 px-5">
            <div className="">
              <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                Name
              </label>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    hint={errors?.name?.message}
                    invalid={errors?.name}
                  />
                )}
              />
            </div>

            <div className="relative">
              <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                Members
              </label>

              <Controller
                name="users"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      ...usersList.filter(
                        (u) =>
                          ![...(selectedUsers || [])]
                            .map((s) => s.userName)
                            .includes(u.userName)
                      ),
                      ...[...(selectedUsers || [])].map((x) => ({
                        ...x,
                        isSelected: true,
                        removeMember: (e) => {
                          e.stopPropagation();
                          removeMember(x.userName);
                        },
                      })),
                    ]}
                    unstyled
                    isMulti
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => (
                      <div className="flex flex-col items-center"></div>
                    )}
                    isOptionDisabled={(o) => o.isSelected}
                    controlShouldRenderValue={false}
                    isClearable={false}
                    placeholder={""}
                    className="drawer-large-select-container"
                    classNamePrefix="drawer-large-select"
                    isSearchable
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                      Option: CustomOption,
                    }}
                  />
                )}
              />

              <div
                className={`flex flex-col px-5 ${
                  [...(selectedUsers || [])].length > 0 ? "py-1" : ""
                } rounded-b-lg border border-t-0 border-[#e5e7eb] bg-[#F8EEF3]`}
              >
                {[...(selectedUsers || [])].map((user, index) => (
                  <div key={index} className="flex items-center space-x-4 py-3">
                    <div className="h-14 w-14 flex-shrink-0">
                      <div className="h-full w-full overflow-hidden rounded-full border border-[#98DDE1] bg-white p-1">
                        <div className="relative h-full w-full">
                          <Avatar
                            text={user.name || ""}
                            alt={user.userName}
                            backgrounds={[
                              "#f28482",
                              "#84a59d",
                              "#b08968",
                              "#eb5e28",
                              "#b8c0ff",
                            ]}
                            src=""
                            shape="circle"
                            className="absolute object-cover text-xs !font-normal text-white"
                            textColor={"#fff"}
                            textProcessor={(text) =>
                              text
                                .match(/(^\S\S?|\b\S)?/g)
                                .join("")
                                .match(/(^\S|\S$)?/g)
                                .join("")
                                .toUpperCase()
                            }
                            // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-grow flex-col space-y-1 truncate">
                      <span className="truncate text-[#33475B]">
                        {user.name}
                      </span>
                      <span className="truncate text-xs text-[#7B93AF]">
                        {user.role}
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => removeMember(user.userName)}
                      className="flex-shrink-0 cursor-pointer"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 6L6 18"
                          stroke="#32A6AE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L18 18"
                          stroke="#32A6AE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>

              <span>{errors?.users?.message}</span>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default TeamCreate;

export const CustomOption = ({ ...props }) => {
  return (
    <components.Option {...props} disabled={props.value.isSelected}>
      <div
        className={`flex items-center space-x-4 bg-[#F8EEF3] px-5 py-1 py-3 ${
          props.value.isSelected && "bg-[#dfd6da]"
        }`}
      >
        <div className="h-14 w-14 flex-shrink-0">
          <div className="h-full w-full overflow-hidden rounded-full border border-[#98DDE1] bg-white p-1">
            <div className="relative h-full w-full">
              <Avatar
                text={props.value.name || ""}
                alt={props.value.userName}
                backgrounds={[
                  "#f28482",
                  "#84a59d",
                  "#b08968",
                  "#eb5e28",
                  "#b8c0ff",
                ]}
                src=""
                shape="circle"
                className="absolute object-cover text-xs !font-normal text-white"
                textColor={"#fff"}
                textProcessor={(text) =>
                  text
                    .match(/(^\S\S?|\b\S)?/g)
                    .join("")
                    .match(/(^\S|\S$)?/g)
                    .join("")
                    .toUpperCase()
                }
                // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-grow flex-col space-y-1 truncate">
          <span className="truncate text-[#33475B]">{props.value.name}</span>
          <span className="truncate text-xs text-[#7B93AF]">
            {props.value.role}
          </span>
        </div>
        {props.value.isSelected ? (
          <>
            <button
              type="button"
              onClick={props.value.removeMember}
              className="flex-shrink-0 cursor-pointer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="#32A6AE"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="#32A6AE"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        ) : (
          <>
            <div className="flex-shrink-0 cursor-pointer">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_15416_66024)">
                  <path
                    d="M25.4853 16.9999H8.51472"
                    stroke="#32A6AE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 8.51465V25.4852"
                    stroke="#32A6AE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_15416_66024">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(17 0.0292969) rotate(45)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </>
        )}
      </div>
    </components.Option>
  );
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 17.4167C15.4183 17.4167 19 14.1334 19 10.0833C19 6.03324 15.4183 2.75 11 2.75C6.58172 2.75 3 6.03324 3 10.0833C3 14.1334 6.58172 17.4167 11 17.4167Z"
          stroke="#CACED8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 19.2502L16.65 15.2627"
          stroke="#CACED8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};
