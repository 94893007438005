// import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import Profile from "../pages/dashboard/Profile";
import Surveys from "../pages/dashboard/Surveys";
import Events from "../pages/dashboard/Events";
import SystemDashboard from "../pages/dashboard/SystemDashboard";
import SurveyActivity from "../pages/dashboard/SurveyActivity";
import QualityAssurance from "../pages/dashboard/QualityAssurance";
import UsersManagement from "../pages/dashboard/UsersManagement";
import JibeDashboard from "../pages/dashboard/JibeDashboard";
import CoachAnalysis from "../pages/dashboard/CoachAnalysis";

const routes = [
  {
    keyIndex: "1",
    type: "DASHBOARD",
    icon: (active) => (
      <div className="">
        <svg
          viewBox="0 0 20 20"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM2.16667 13.8333V2.16667H7.16667V13.8333H2.16667ZM13.8333 13.8333H8.83333V8H13.8333V13.8333ZM13.8333 6.33333H8.83333V2.16667H13.8333V6.33333Z"
            fill={`url(#${active ? "active" : "default"})`}
          />
          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    path: "/dashboard/overview",
    inMenu: true,
    title: "System Dashboard",
    roles: [],
    privileges: [],
    element: <SystemDashboard />,
    handle: {
      crumb: () => <Link to="/dashboard/overview">System Dashboard</Link>,
    },
    // disabled: true,
  },
  {
    keyIndex: "2",
    type: "DASHBOARD",
    icon: (active) => (
      <div className="">
        <svg
          viewBox="0 0 20 20"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 9.58336C17.5029 10.6832 17.2459 11.7683 16.75 12.75C16.162 13.9265 15.2581 14.916 14.1395 15.6078C13.021 16.2995 11.7319 16.6662 10.4167 16.6667C9.31678 16.6696 8.23176 16.4126 7.25 15.9167L2.5 17.5L4.08333 12.75C3.58744 11.7683 3.33047 10.6832 3.33333 9.58336C3.33384 8.26815 3.70051 6.97907 4.39227 5.86048C5.08402 4.7419 6.07355 3.838 7.25 3.25002C8.23176 2.75413 9.31678 2.49716 10.4167 2.50002H10.8333C12.5703 2.59585 14.2109 3.32899 15.441 4.55907C16.671 5.78915 17.4042 7.42973 17.5 9.16669V9.58336Z"
            stroke={`url(#${active ? "active" : "default"})`}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />

          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    path: "/dashboard/jibe",
    inMenu: true,
    title: "Jibe Dashboard",
    roles: [],
    privileges: [],
    element: <JibeDashboard />,
    // disabled: true,
  },
  {
    keyIndex: "3",
    type: "DASHBOARD",
    icon: (active) => (
      <div className="">
        <svg
          viewBox="0 0 20 20"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 1.66663C13.4205 1.66663 14.1667 2.41282 14.1667 3.33329H16.6722C17.1293 3.33329 17.5 3.70408 17.5 4.16113V9.48339C17.5 9.60522 17.4968 12.0499 17.4938 14.3388C17.4909 16.5459 15.7001 18.3333 13.493 18.3333C12.7425 18.3333 12.1667 18.3333 12.0946 18.3333H10.1957H6.5C4.29086 18.3333 2.5 16.5424 2.5 14.3333C2.5 12.6051 2.5 10.9501 2.5 10.8508V4.16113C2.5 3.70393 2.87079 3.33329 3.32783 3.33329H5.83333C5.83333 2.41282 6.57953 1.66663 7.5 1.66663H12.5ZM5.83333 4.99996H4.16667V13.6666C4.16667 15.3235 5.50981 16.6666 7.16667 16.6666H12.8333C14.4902 16.6666 15.8333 15.3235 15.8333 13.6666V4.99996H14.1667C14.1667 5.92043 13.4205 6.66663 12.5 6.66663H7.5C6.57953 6.66663 5.83333 5.92043 5.83333 4.99996ZM6.66667 13.3333C7.1269 13.3333 7.5 13.7064 7.5 14.1666C7.5 14.6269 7.1269 15 6.66667 15C6.20643 15 5.83333 14.6269 5.83333 14.1666C5.83333 13.7064 6.20643 13.3333 6.66667 13.3333ZM6.66667 10.8333C7.1269 10.8333 7.5 11.2064 7.5 11.6666C7.5 12.1269 7.1269 12.5 6.66667 12.5C6.20643 12.5 5.83333 12.1269 5.83333 11.6666C5.83333 11.2064 6.20643 10.8333 6.66667 10.8333ZM6.66667 8.33329C7.1269 8.33329 7.5 8.70639 7.5 9.16663C7.5 9.62686 7.1269 9.99996 6.66667 9.99996C6.20643 9.99996 5.83333 9.62686 5.83333 9.16663C5.83333 8.70639 6.20643 8.33329 6.66667 8.33329ZM12.5 3.33329H7.5V4.99996H12.5V3.33329Z"
            fill={`url(#${active ? "active" : "default"})`}
          />

          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    path: "/dashboard/survey-activity",
    inMenu: true,
    title: "Survey Activity",
    roles: [],
    privileges: [],
    element: <SurveyActivity />,
    // disabled: true,
  },
  {
    keyIndex: "4",
    type: "DASHBOARD",
    icon: (active) => (
      <div className="">
        <svg
          viewBox="0 0 20 20"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0.833374L16.8474 2.35502C17.2287 2.43976 17.5 2.77793 17.5 3.16852V11.4908C17.5 13.1625 16.6645 14.7237 15.2735 15.651L10 19.1667L4.7265 15.651C3.33551 14.7237 2.5 13.1625 2.5 11.4908V3.16852C2.5 2.77793 2.77128 2.43976 3.15256 2.35502L10 0.833374ZM10 2.5407L4.16667 3.83699V11.4908C4.16667 12.6053 4.72367 13.646 5.651 14.2643L10 17.1636L14.349 14.2643C15.2763 13.646 15.8333 12.6053 15.8333 11.4908V3.83699L10 2.5407ZM13.7103 6.85157L14.8888 8.03007L9.5855 13.3334L6.04999 9.79787L7.22851 8.61929L9.58492 10.9758L13.7103 6.85157Z"
            fill={`url(#${active ? "active" : "default"})`}
          />
          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    path: "/dashboard/quality-assurance",
    inMenu: true,
    title: "Quality Assurance",
    roles: [],
    privileges: [],
    element: <QualityAssurance />,
    // disabled: true,
  },

  {
    keyIndex: "5",
    type: "DASHBOARD",
    icon: (active) => (
      <div className="">
        <svg
          viewBox="0 0 20 18"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 8V0H6V6H0V18H20V8H14ZM8 2H12V16H8V2ZM2 8H6V16H2V8ZM18 16H14V10H18V16Z"
            fill={`url(#${active ? "active" : "default"})`}
          />

          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    path: "/dashboard/coaching",
    inMenu: true,
    title: "Coach Analysis",
    roles: [],
    privileges: [],
    element: <CoachAnalysis />,
    handle: {
      crumb: () => <Link to="/dashboard/coaching">Coaching</Link>,
    },
  },

  {
    keyIndex: "6",
    type: "OTHER",
    icon: (active) => (
      <div className="">
        <svg
          viewBox="0 0 20 20"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_294_2989)">
            <path
              d="M15.8333 3.33329H15V1.66663H13.3333V3.33329H6.66667V1.66663H5V3.33329H4.16667C3.24167 3.33329 2.50833 4.08329 2.50833 4.99996L2.5 16.6666C2.5 17.5833 3.24167 18.3333 4.16667 18.3333H15.8333C16.75 18.3333 17.5 17.5833 17.5 16.6666V4.99996C17.5 4.08329 16.75 3.33329 15.8333 3.33329ZM15.8333 16.6666H4.16667V8.33329H15.8333V16.6666ZM15.8333 6.66663H4.16667V4.99996H15.8333V6.66663ZM10 10.8333H14.1667V15H10V10.8333Z"
              fill={`url(#${active ? "active" : "default"})`}
            />
          </g>

          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    path: "/dashboard/events",
    inMenu: true,
    title: "Events",
    roles: [],
    privileges: [],
    element: <Events />,
    handle: {
      crumb: () => <Link to="/dashboard/events">Events</Link>,
    },
  },
  {
    keyIndex: "7",
    type: "OTHER",
    icon: (active) => (
      <div className="">
        <svg
          viewBox="0 0 20 20"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_294_2994)">
            <path
              d="M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333ZM5.83333 8.33333H7.5V14.1667H5.83333V8.33333ZM9.16667 5.83333H10.8333V14.1667H9.16667V5.83333ZM12.5 10.8333H14.1667V14.1667H12.5V10.8333Z"
              fill={`url(#${active ? "active" : "default"})`}
            />
          </g>

          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    path: "/dashboard/surveys",
    inMenu: true,
    title: "Surveys",
    roles: [],
    privileges: [],
    element: <Surveys />,
    handle: {
      crumb: () => <Link to="/dashboard/surveys">Surveys</Link>,
    },
  },
  {
    keyIndex: "8",
    type: "OTHER",
    icon: (active) => (
      <div className="">
        <svg
          viewBox="0 0 20 20"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_294_3002)">
            <path
              d="M13.8917 10.9417C15.0333 11.7167 15.8333 12.7667 15.8333 14.1667V16.6666H19.1667V14.1667C19.1667 12.35 16.1917 11.275 13.8917 10.9417Z"
              fill={`url(#${active ? "active" : "default"})`}
            />
            <path
              d="M12.5 10C14.3417 10 15.8333 8.50837 15.8333 6.66671C15.8333 4.82504 14.3417 3.33337 12.5 3.33337C12.1083 3.33337 11.7417 3.41671 11.3917 3.53337C12.0833 4.39171 12.5 5.48337 12.5 6.66671C12.5 7.85004 12.0833 8.94171 11.3917 9.80004C11.7417 9.91671 12.1083 10 12.5 10Z"
              fill={`url(#${active ? "active" : "default"})`}
            />
            <path
              d="M7.49996 10C9.34163 10 10.8333 8.50837 10.8333 6.66671C10.8333 4.82504 9.34163 3.33337 7.49996 3.33337C5.65829 3.33337 4.16663 4.82504 4.16663 6.66671C4.16663 8.50837 5.65829 10 7.49996 10ZM7.49996 5.00004C8.41663 5.00004 9.16663 5.75004 9.16663 6.66671C9.16663 7.58337 8.41663 8.33337 7.49996 8.33337C6.58329 8.33337 5.83329 7.58337 5.83329 6.66671C5.83329 5.75004 6.58329 5.00004 7.49996 5.00004Z"
              fill={`url(#${active ? "active" : "default"})`}
            />
            <path
              d="M7.49998 10.8334C5.27498 10.8334 0.833313 11.95 0.833313 14.1667V16.6667H14.1666V14.1667C14.1666 11.95 9.72498 10.8334 7.49998 10.8334ZM12.5 15H2.49998V14.175C2.66665 13.575 5.24998 12.5 7.49998 12.5C9.74998 12.5 12.3333 13.575 12.5 14.1667V15Z"
              fill={`url(#${active ? "active" : "default"})`}
            />
          </g>

          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    path: "/dashboard/users-management",
    inMenu: true,
    title: "Users Management",
    roles: [],
    privileges: [],
    element: <UsersManagement />,
    handle: {
      crumb: () => (
        <Link to="/dashboard/users-management">Users Management</Link>
      ),
    },
  },
];

export default routes;
