import React, { useState } from "react";

import Dialog from "rc-dialog";

const RFCSelect = () => {
  const [query, setQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [RFCs] = useState([
    {
      id: "1",
      name: "Mexico City",
    },
    {
      id: "2",
      name: "Mexico City",
    },
    {
      id: "3",
      name: "Mexico City",
    },
    {
      id: "4",
      name: "Mexico City",
    },
    {
      id: "11",
      name: "Mexico City",
    },
    {
      id: "12",
      name: "Mexico City",
    },
    {
      id: "13",
      name: "Mexico City",
    },
    {
      id: "14",
      name: "Mexico City",
    },
    {
      id: "21",
      name: "Mexico City",
    },
    {
      id: "22",
      name: "Mexico City",
    },
    {
      id: "23",
      name: "Mexico City",
    },
    {
      id: "24",
      name: "Mexico City",
    },
  ]);
  const [fillteredRFCs, setFilteredRFCs] = useState([...RFCs]);
  const [selected, setSelected] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAdd = (id) => {
    const tmp = [...fillteredRFCs];
    const index = tmp.findIndex((rfc) => rfc.id === id);
    const rfc = tmp.splice(index, 1);
    setFilteredRFCs([...tmp]);
    setSelected([...selected, ...rfc]);
  };

  const handleRemove = (id) => {
    const tmp = [...selected];
    const index = tmp.findIndex((rfc) => rfc.id === id);
    tmp.splice(index, 1);
    setSelected([...tmp]);
    setFilteredRFCs(
      RFCs.filter(
        (rfc) => tmp.map((x) => x.id).findIndex((y) => y === rfc.id) === -1
      )
    );
  };

  return (
    <>
      {selected.length > 0 ? (
        <div
          className={`inline-flex items-center flex-wrap border px-4 pb-2 mr-2 mt-2 border-cyan-300 rounded-full`}
        >
          <button
            type="button"
            className="flex truncate items-center text-sm font-normal mt-2 mr-2 text-orange-400"
            onClick={showModal}
          >
            <span>Add RFC</span>
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="flex-shrink-0 h-5 w-5 font-black text-orange-400"
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>

          {[...selected].slice(0, 3).map((rfc, index) => (
            <div
              key={index}
              className="truncate flex items-center space-x-2 mt-2 mr-3 last:mr-0"
            >
              <span>{rfc.name}</span>
              <button
                onClick={() => handleRemove(rfc.id)}
                type="button"
                className=""
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-6 h-6 text-cyan-300 inline-block cursor-pointer"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </button>
            </div>
          ))}

          {selected.length > 3 && (
            <Selection selection={selected} handleClick={handleRemove} />
          )}
        </div>
      ) : (
        <button
          type="button"
          onClick={showModal}
          className="inline-block relative overflow-hidden truncate text-sm font-semibold bg-purple-100 py-2.5 px-5 mr-2 mt-2 rounded-full"
        >
          <svg
            width="31"
            height="40"
            viewBox="0 0 31 40"
            className="absolute left-0 top-0"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.7"
              d="M0 20C0 8.9543 8.9543 0 20 0H21C4 20.5 31 40 31 40H20C8.9543 40 0 31.0457 0 20Z"
              fill="url(#paint0_linear_694_1301)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_694_1301"
                x1="20"
                y1="-1.88392e-06"
                x2="33"
                y2="44.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D9CAEB" />
                <stop offset="1" stopColor="#F1EAFA" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block w-5 h-5 text-cyan-500 mr-2"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
          </svg>
          <span>Add Reason for Contact</span>
        </button>
      )}

      <Dialog
        visible={isModalOpen}
        className="!max-w-sm"
        onClose={handleCancel}
      >
        <div className="flex flex-col">
          <span>Select a Reason for Contact:</span>
          <div className="relative mt-1">
            <input
              type="text"
              className="bg-[#F8F5FD] p-3.5 pr-7 rounded-full w-full focus:outline-none placeholder:text-cyan-500 text-[#33475B]"
              placeholder="Search for reason for contact ..."
              onChange={(e) => setQuery(e.target.value)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/search.svg`}
              alt=""
              className="absolute top-1/2 -translate-y-1/2 right-3.5"
            />
          </div>
        </div>
        <div className="mt-4 max-h-[256px] scrollbar-cyan-500 overflow-hidden overflow-y-auto -ml-4 -mr-4">
          <div className="flex flex-col">
            {[...fillteredRFCs]
              .filter((a) => a.name.includes(query))
              .map((rfc, index) => {
                return (
                  <div
                    onClick={() => handleAdd(rfc.id)}
                    type="button"
                    key={rfc.id + "" + index}
                    className="flex rfc-item justify-between cursor-pointer items-center hover:bg-[#F8F5FD] px-4"
                  >
                    <div className="flex space-x-2 justify-between items-center w-full border-b border-cyan-200 py-2">
                      <div className="flex items-center space-x-2">
                        <div className="w-8 h-8 font-bold text-2xl bg-cyan-300 text-white flex justify-center items-center rounded-md">
                          {rfc.name.toUpperCase().substring(0, 1)}
                        </div>
                        <div className="flex space-x-1 items-center">
                          <span className="text-[#33475B] select-none">
                            {rfc.name}
                          </span>
                        </div>
                      </div>

                      <div className="">
                        <a
                          href={`/#${index}`}
                          onClick={(e) => e.stopPropagation()}
                          className="analyze-btn flex whitespace-nowrap items-center bg-orange-400 rounded-full text-xs px-3 py-1.5 text-white"
                        >
                          <span>Analyze</span>
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="flex-shrink-0 h-4 w-4 -mr-2 font-black text-white"
                            aria-hidden="true"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RFCSelect;

const Selection = ({ selection = [], handleClick }) => {
  const [query, setQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={showModal}
        className="truncate text-gray-400 text-sm mt-2"
      >
        more ...
      </button>

      <Dialog
        visible={isModalOpen}
        className="!max-w-sm"
        onClose={handleCancel}
      >
        <div className="flex flex-col">
          <span>Selected Reasons for Contact:</span>
          <div className="relative mt-1">
            <input
              type="text"
              className="bg-[#F8F5FD] p-3.5 pr-7 rounded-full w-full focus:outline-none placeholder:text-cyan-500 text-[#33475B]"
              placeholder="Search for reason for contact ..."
              onChange={(e) => setQuery(e.target.value)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/search.svg`}
              alt=""
              className="absolute top-1/2 -translate-y-1/2 right-3.5"
            />
          </div>
        </div>
        <div className="mt-4 max-h-[256px] scrollbar-cyan-500 overflow-hidden overflow-y-auto -ml-4 -mr-4">
          <div className="flex flex-col">
            {[...selection]
              .filter((a) => a.name.includes(query))
              .map((rfc, index) => {
                return (
                  <div
                    onClick={() => handleClick(rfc.id)}
                    type="button"
                    key={rfc.id + "" + index}
                    className="flex rfc-item justify-between cursor-pointer items-center hover:bg-[#F8F5FD] px-4"
                  >
                    <div className="flex space-x-2 justify-between items-center w-full border-b border-cyan-200 py-2">
                      <div className="flex items-center space-x-2">
                        <div className="w-8 h-8 font-bold text-2xl bg-cyan-300 text-white flex justify-center items-center rounded-md">
                          {rfc.name.toUpperCase().substring(0, 1)}
                        </div>
                        <div className="flex space-x-1 items-center">
                          <span className="text-[#33475B] select-none">
                            {rfc.name}
                          </span>
                        </div>
                      </div>

                      <div className="">
                        <a
                          href={`/#${index}`}
                          onClick={(e) => e.stopPropagation()}
                          className="analyze-btn flex whitespace-nowrap items-center bg-orange-400 rounded-full text-xs px-3 py-1.5 text-white"
                        >
                          <span>Analyze</span>
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="flex-shrink-0 h-4 w-4 -mr-2 font-black text-white"
                            aria-hidden="true"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Dialog>
    </>
  );
};
