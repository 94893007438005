import React, { Fragment, useEffect, useState } from "react";
import { numberWithCommas } from "../../../helpers/util";
import servicesServices from "../../../services/services.services";
import { isEmpty } from "lodash";
import { NavLink } from "react-router-dom";

const QA = ({ range = 1, rangeObj }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data: res } = await servicesServices.fetchSurveyQA({
        fromDate: `${rangeObj.from.month}/${rangeObj.from.day}/${rangeObj.from.year}`,
        toDate: `${rangeObj.to.month}/${rangeObj.to.day}/${rangeObj.to.year}`,
      });
      setData(res.result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(rangeObj.to) && !isEmpty(rangeObj.from)) fetchData();
  }, [rangeObj]);

  return (
    <>
      <div className="box-wrapper">
        <div className="box-header">
          <div className="inline-flex items-center gap-4">
            <div className="inline-flex items-center gap-2">
              <h1 className="box-title">QA</h1>
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M8.2 5.4H6.8V4H8.2M8.2 11H6.8V6.8H8.2M7.5 0.5C6.58075 0.5 5.67049 0.68106 4.82122 1.03284C3.97194 1.38463 3.20026 1.90024 2.55025 2.55025C1.2375 3.86301 0.5 5.64348 0.5 7.5C0.5 9.35651 1.2375 11.137 2.55025 12.4497C3.20026 13.0998 3.97194 13.6154 4.82122 13.9672C5.67049 14.3189 6.58075 14.5 7.5 14.5C9.35651 14.5 11.137 13.7625 12.4497 12.4497C13.7625 11.137 14.5 9.35651 14.5 7.5C14.5 6.58075 14.3189 5.67049 13.9672 4.82122C13.6154 3.97194 13.0998 3.20026 12.4497 2.55025C11.7997 1.90024 11.0281 1.38463 10.1788 1.03284C9.3295 0.68106 8.41925 0.5 7.5 0.5Z"
                    fill="#7B93AF"
                  />
                </svg>
              </div>
            </div>
          </div>

          <NavLink
            to="/dashboard/quality-assurance"
            className="btn btn-secondary btn-pill !gap-6 !p-3 !px-5"
            // onClick={toggleDrawer}
          >
            <span className="cursor-pointer">More Analysis</span>
          </NavLink>
        </div>

        <div className="w-full border-t border-[#C0EAED] pt-4">
          <div className="flex w-full items-center justify-between gap-8 text-[#474E55]">
            <div className="grid w-full max-w-sm grid-cols-[1fr_1.5fr] gap-4 text-sm">
              {loading ? (
                <></>
              ) : (
                <>
                  <Fragment>
                    <span>QA Survey Count</span>
                    <span className="font-semibold">
                      {numberWithCommas(data?.qaSurveyCount || 0)} (
                      {data?.qaSurveyCount || 0 / data?.ticketCount || 0}% of{" "}
                      {data?.ticketCount || 0} tickets)
                    </span>
                  </Fragment>

                  <Fragment>
                    <span>Audited Agent Count</span>
                    <span className="font-semibold">
                      {numberWithCommas(data?.agentAuditedCount || 0)} (
                      {(data?.agentCount * 100) / data?.agentAuditedCount || 0}%
                      of {data?.agentCount || 0} agents)
                    </span>
                  </Fragment>

                  <Fragment>
                    <span>Coaching Counts</span>
                    <span className="font-semibold">
                      {numberWithCommas(data?.coachingCount || 0)} (
                      {(
                        (data?.agentCount * 100) / data?.coachingCount || 0
                      ).toFixed(2)}
                      % of {data?.agentCount || 0} agents)
                    </span>
                  </Fragment>
                </>
              )}
            </div>
            <div className="flex-shirink-0 flex min-w-[180px] flex-col items-center gap-2">
              <span className="text-2xl font-medium">
                {data?.surveysPercentScoreAve || 0}%
              </span>
              <span className="text-[#32A6AE]">AVG QA Score</span>
              <div className="flex items-center gap-2 text-sm">
                <span style={{ padding: "0px" }}>
                  {/* {upDownPercentAmount > 0 ? ( */}
                  <svg
                    height={"1rem"}
                    width={"1rem"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 25 25"
                    strokeWidth="4"
                    stroke="#11b4aa"
                    style={{ padding: "0px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                  {/* ) : (
                    upDownPercentAmount < 0 && (
                      <svg
                        height={"1rem"}
                        width={"1rem"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 25 25"
                        strokeWidth="4"
                        stroke="#fe765c"
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
                        />
                      </svg>
                    )
                  )} */}
                </span>
                <span
                  style={{
                    margin: "0 0 0 2px",
                  }}
                >
                  {/* {upDownPercentAmount}% */}
                  10%
                </span>
                <span
                  style={{
                    backgroundColor: "#f2f6f9",
                    borderRadius: "5px",
                    color: "#98abc2",
                    padding: "2px 5px",
                    margin: "0px",
                  }}
                >
                  {range <= 1 ? "24 h" : `${range} d`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QA;
