import React, { Fragment, useEffect, useState } from "react";

import { Avatar } from "@agney/react-avatar";
import Drawer from "react-modern-drawer";
import { Link } from "react-router-dom";
import TeamUpdate from "./teamUpdate";

const TeamViewBox = ({ team, isOpen, handleClose }) => {
  const [agents, setAgents] = useState([]);
  const [nonAgents, setNonAgents] = useState([]);
  const [drawers, setDrawers] = useState({
    update: false,
  });

  useEffect(() => {
    setNonAgents(
      [...(team.users || [])].filter(
        (user) => !user.role.toLowerCase().includes("agent")
      ).sort((a, b) => a.roleId - b.roleId).reverse()
    );
    setAgents(
      [...(team.users || [])].filter((user) =>
        user.role.toLowerCase().includes("agent")
      )
    );
  }, [team]);

  const openDrawer = (type) => {
    let drawersModified = { ...drawers };
    Object.keys(drawersModified).forEach(
      // eslint-disable-next-line
      (key) => {
        const value = drawersModified[key];
        drawersModified = {
          ...drawersModified,
          [key]: key === type ? !value : false,
        };
      }
    );
    setDrawers(drawersModified);
  };

  const closeDrawer = (type) => {
    let drawersModified = { ...drawers };
    Object.keys(drawersModified).forEach(
      // eslint-disable-next-line
      (key) => {
        const value = drawersModified[key];
        drawersModified = {
          ...drawersModified,
          [key]: key === type ? !value : value,
        };
      }
    );
    setDrawers(drawersModified);
  };

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        direction="right"
        className="max-h-screen overflow-hidden overflow-y-auto rounded-l-lg !bg-[#FDF9FB] pb-5"
        size={380}
        enableOverlay={true}
        overlayOpacity={0.1}
      >
        <div className="min-h-[201px] w-full bg-gradient-to-r from-[rgba(0,0,0,0)] via-[#FF7700] to-[rgba(0,0,0,0)]">
          <div
            className="relative flex min-h-[200px] w-full flex-col items-center justify-center space-y-3 bg-white"
            style={{
              backgroundImage:
                "url(/assets/images/patterns/drawer-header-pattern.png)",
            }}
          >
            <div className="absolute left-0 top-0 flex w-full justify-between px-4 py-4">
              <div className="">
                <button onClick={handleClose}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 12H5"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 19L5 12L12 5"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex flex-col space-y-4">
                <TeamUpdate.TeamUpdateBtn
                  toggleDrawer={() => {
                    openDrawer("update");
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </TeamUpdate.TeamUpdateBtn>

                {/* <button onClick={handleClose}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.59009 13.5098L15.4201 17.4898"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.4101 6.50977L8.59009 10.4898"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button> */}
              </div>
            </div>

            <div className="to-[rgba(255,119,0, 0.7)] mx-auto flex h-[92px] w-[92px] items-center justify-center rounded-full bg-white bg-gradient-to-tr from-[#FF7700] p-[1px]">
              <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white p-[6.35px]">
                <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
                  <Avatar
                    text={team.name || ""}
                    alt={team.userName}
                    backgrounds={[
                      "#f28482",
                      "#84a59d",
                      "#b08968",
                      "#eb5e28",
                      "#b8c0ff",
                    ]}
                    src=""
                    shape="circle"
                    className="absolute object-cover text-2xl !font-normal text-white"
                    textColor={"#fff"}
                    textProcessor={(text) =>
                      text
                        .match(/(^\S\S?|\b\S)?/g)
                        .join("")
                        .match(/(^\S|\S$)?/g)
                        .join("")
                        .toUpperCase()
                    }
                    // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                  />
                </div>
              </div>
            </div>

            <span className="font-semibold text-[#33475B]">{team.name}</span>
          </div>
        </div>

        {nonAgents && nonAgents.length > 0 && (
          <>
            <div className="relative px-8 py-4">
              {nonAgents.map((user, index) => (
                <Link
                  onClick={handleClose}
                  to="/dashboard/users-management/#"
                  state={{ tab: "agents", user }}
                  key={user.id}
                >
                  <div className="agent-item group relative z-0 flex cursor-pointer items-center space-x-4 py-3 transition-all hover:rounded-xl hover:border hover:border-[#C0EAED] hover:bg-white hover:px-4 hover:shadow-[0px_2px_8px_0px_rgba(0,0,0,0.10)]">
                    <div className="h-14 w-14 flex-shrink-0">
                      <div className="h-full w-full overflow-hidden rounded-full border border-[#98DDE1] bg-white p-1">
                        <div className="relative h-full w-full">
                          <Avatar
                            text={user.name || ""}
                            alt={user.userName}
                            backgrounds={[
                              "#f28482",
                              "#84a59d",
                              "#b08968",
                              "#eb5e28",
                              "#b8c0ff",
                            ]}
                            src=""
                            shape="circle"
                            className="absolute object-cover text-xs !font-normal text-white"
                            textColor={"#fff"}
                            textProcessor={(text) =>
                              text
                                .match(/(^\S\S?|\b\S)?/g)
                                .join("")
                                .match(/(^\S|\S$)?/g)
                                .join("")
                                .toUpperCase()
                            }
                            // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-grow flex-col space-y-1 truncate">
                      <span className="truncate text-[#33475B]">
                        {user.name}
                      </span>
                      <span className="text-xs text-[#7B93AF]">
                        {user.role}
                      </span>
                      <span className="truncate text-xs text-[#7B93AF]">
                        {user.email}
                      </span>
                    </div>
                    <div className="hidden flex-shrink-0 group-hover:inline-block">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 17L18 12L13 7"
                          stroke="#7B93AF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 17L11 12L6 7"
                          stroke="#7B93AF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </>
        )}

        {agents && agents.length > 0 && nonAgents && nonAgents.length > 0 && (
          <>
            <svg
              width="387"
              height="1"
              viewBox="0 0 387 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-1"
                y1="0.5"
                x2="388"
                y2="0.500034"
                stroke="url(#paint0_linear_15416_65589)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_15416_65589"
                  x1="-1"
                  y1="1"
                  x2="388"
                  y2="1"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FF7700" stop-opacity="0" />
                  <stop offset="0.526515" stopColor="#FF7700" />
                  <stop offset="1" stopColor="#FF7700" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </>
        )}

        {agents && agents.length > 0 && (
          <>
            <div className="agents-list relative px-8 py-4">
              {agents.map((user, index) => (
                <Link
                  onClick={handleClose}
                  to={"/dashboard/users-management"}
                  state={{ tab: "agents", user }}
                  key={user.id}
                >
                  <div className="agent-item group relative z-0 flex cursor-pointer items-center space-x-4 py-3 transition-all hover:rounded-xl hover:border hover:border-[#C0EAED] hover:bg-white hover:px-4 hover:shadow-[0px_2px_8px_0px_rgba(0,0,0,0.10)]">
                    <div className="h-14 w-14 flex-shrink-0">
                      <div className="h-full w-full p-1 overflow-hidden rounded-full border border-[#98DDE1] bg-white">
                        <div className="relative h-full w-full">
                          <Avatar
                            text={user.name || ""}
                            alt={user.userName}
                            backgrounds={[
                              "#f28482",
                              "#84a59d",
                              "#b08968",
                              "#eb5e28",
                              "#b8c0ff",
                            ]}
                            src=""
                            shape="circle"
                            className="absolute object-cover text-xs !font-normal text-white"
                            textColor={"#fff"}
                            textProcessor={(text) =>
                              text
                                .match(/(^\S\S?|\b\S)?/g)
                                .join("")
                                .match(/(^\S|\S$)?/g)
                                .join("")
                                .toUpperCase()
                            }
                            // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-grow flex-col space-y-1 truncate">
                      <span className="truncate text-[#33475B]">
                        {user.name}
                      </span>
                      <span className="text-xs text-[#7B93AF]">
                        {user.role}
                      </span>
                      <span className="truncate text-xs text-[#7B93AF]">
                        {user.email}
                      </span>
                    </div>
                    <div className="hidden flex-shrink-0 group-hover:inline-block">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 17L18 12L13 7"
                          stroke="#7B93AF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 17L11 12L6 7"
                          stroke="#7B93AF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </>
        )}
      </Drawer>

      {team && team.name && (
        <TeamUpdate.TeamUpdateBox
          team={team}
          isOpen={drawers.update}
          handleClose={() => closeDrawer("update")}
        />
      )}
    </>
  );
};

const TeamViewBtn = ({ toggleDrawer, className, children }) => {
  return (
    <>
      <button onClick={toggleDrawer} className={className || "px-2"}>
        {children || "View"}
      </button>
    </>
  );
};

const TeamView = {
  TeamViewBtn,
  TeamViewBox,
};

export default TeamView;
