import Agents from "../../components/coach-analysis/agents/agents";
import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import CoachingSummery from "../../components/coach-analysis/coaching-summery/CoachingSummery"
import React from "react";

const Coaching = () => {
  return (
    <div className="relative flex flex-col gap-6">
      <div className="">
        <Breadcrumbs />
      </div>

      <div>
        <CoachingSummery />
      </div>

      <div>
        <Agents />
      </div>
    </div>
  );
};

export default Coaching;
