import "../../style.css";

import React, { useState } from "react";

import AgentTeams from "./agentTeams";
import AgentUpdate from "./agentUpdate";
import { Avatar } from "@agney/react-avatar";
import Drawer from "react-modern-drawer";
import Empty from "../../../shared/empty";
import dayjs from "dayjs";

const AgentViewBox = ({ agent, isOpen, handleClose }) => {
  const [drawers, setDrawers] = useState({
    update: false,
  });

  const openDrawer = (type) => {
    let drawersModified = { ...drawers };
    Object.keys(drawersModified).forEach(
      // eslint-disable-next-line
      (key) => {
        const value = drawersModified[key];
        drawersModified = {
          ...drawersModified,
          [key]: key === type ? !value : false,
        };
      }
    );
    setDrawers(drawersModified);
  };

  const closeDrawer = (type) => {
    let drawersModified = { ...drawers };
    Object.keys(drawersModified).forEach(
      // eslint-disable-next-line
      (key) => {
        const value = drawersModified[key];
        drawersModified = {
          ...drawersModified,
          [key]: key === type ? !value : value,
        };
      }
    );
    setDrawers(drawersModified);
  };

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        direction="right"
        className="max-h-screen overflow-hidden overflow-y-auto rounded-l-lg !bg-[#FDF9FB] pb-5"
        size={380}
        enableOverlay={true}
        overlayOpacity={0.1}
      >
        <div className="min-h-[201px] w-full bg-gradient-to-r from-[rgba(0,0,0,0)] via-[#FF7700] to-[rgba(0,0,0,0)]">
          <div
            className="relative flex min-h-[200px] w-full flex-col items-center justify-center space-y-3 bg-white"
            style={{
              backgroundImage:
                "url(/assets/images/patterns/drawer-header-pattern.png)",
            }}
          >
            <div className="absolute left-0 top-0 flex w-full justify-between px-4 py-4">
              <div className="">
                <button onClick={handleClose}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 12H5"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 19L5 12L12 5"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex flex-col space-y-4">
                <AgentUpdate.AgentUpdateBtn
                  toggleDrawer={() => {
                    openDrawer("update");
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </AgentUpdate.AgentUpdateBtn>

                {/* <button onClick={handleClose}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.59009 13.5098L15.4201 17.4898"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.4101 6.50977L8.59009 10.4898"
                      stroke="#32A6AE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button> */}
              </div>
            </div>

            <div className="to-[rgba(255,119,0, 0.7)] mx-auto flex h-[92px] w-[92px] items-center justify-center rounded-full bg-white bg-gradient-to-tr from-[#FF7700] p-[1px]">
              <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white p-[6.35px]">
                <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
                  <Avatar
                    text={agent.name || ""}
                    alt={agent.userName}
                    backgrounds={[
                      "#f28482",
                      "#84a59d",
                      "#b08968",
                      "#eb5e28",
                      "#b8c0ff",
                    ]}
                    src=""
                    shape="circle"
                    className="absolute object-cover text-2xl !font-normal text-white"
                    textColor={"#fff"}
                    textProcessor={(text) =>
                      text
                        .match(/(^\S\S?|\b\S)?/g)
                        .join("")
                        .match(/(^\S|\S$)?/g)
                        .join("")
                        .toUpperCase()
                    }
                    // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <span className="text-xl font-semibold text-[#33475B]">
                {agent.name}
              </span>
              <span className="text-sm text-[#7B93AF]">
                {agent.email || agent.userName}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4 flex flex-col items-center space-y-4">
          <span>Agent in </span>

          <AgentTeams teamsList={agent.teamNames || []} />
        </div>

        <div className="mt-12 flex items-center justify-between px-5">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-1 text-[#7B93AF]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_15416_65751)">
                  <path
                    d="M14.6665 11.2802V13.2802C14.6672 13.4659 14.6292 13.6497 14.5548 13.8198C14.4804 13.9899 14.3713 14.1426 14.2345 14.2681C14.0977 14.3937 13.9362 14.4892 13.7603 14.5487C13.5844 14.6082 13.398 14.6303 13.2131 14.6136C11.1617 14.3907 9.19113 13.6897 7.45979 12.5669C5.84901 11.5433 4.48335 10.1777 3.45979 8.56689C2.33311 6.8277 1.63195 4.84756 1.41313 2.78689C1.39647 2.60254 1.41838 2.41673 1.47746 2.24131C1.53654 2.06589 1.63151 1.90469 1.7563 1.76797C1.8811 1.63126 2.033 1.52203 2.20232 1.44724C2.37164 1.37245 2.55469 1.33374 2.73979 1.33356H4.73979C5.06333 1.33038 5.37699 1.44495 5.6223 1.65592C5.86761 1.86689 6.02784 2.15986 6.07313 2.48023C6.15754 3.12027 6.31409 3.74871 6.53979 4.35356C6.62949 4.59218 6.6489 4.8515 6.59573 5.10081C6.54256 5.35012 6.41903 5.57897 6.23979 5.76023L5.39313 6.60689C6.34216 8.27592 7.7241 9.65786 9.39313 10.6069L10.2398 9.76023C10.4211 9.58099 10.6499 9.45746 10.8992 9.40429C11.1485 9.35112 11.4078 9.37053 11.6465 9.46023C12.2513 9.68593 12.8797 9.84248 13.5198 9.92689C13.8436 9.97258 14.1394 10.1357 14.3508 10.3852C14.5622 10.6348 14.6746 10.9533 14.6665 11.2802Z"
                    stroke="#7B93AF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_15416_65751">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span>Phone Number</span>
            </div>
            <span>-</span>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-1 text-[#7B93AF]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6667 2.6665H3.33333C2.59695 2.6665 2 3.26346 2 3.99984V13.3332C2 14.0696 2.59695 14.6665 3.33333 14.6665H12.6667C13.403 14.6665 14 14.0696 14 13.3332V3.99984C14 3.26346 13.403 2.6665 12.6667 2.6665Z"
                  stroke="#7B93AF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 6.6665H14"
                  stroke="#7B93AF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6665 1.3335V4.00016"
                  stroke="#7B93AF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.3335 1.3335V4.00016"
                  stroke="#7B93AF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span>Hire Date</span>
            </div>
            <span>
              {agent.hireDate
                ? Number(dayjs(agent.hireDate).format("YYYY")) > 2000
                  ? `${Number(dayjs(agent.hireDate).format("DD"))}/${Number(
                      dayjs(agent.hireDate).format("MM")
                    )}/${
                      Number(dayjs(agent.hireDate).format("YYYY")) > 2000
                        ? Number(dayjs(agent.hireDate).format("YYYY"))
                        : 2000
                    }`
                  : "-"
                : "-"}
            </span>
          </div>
        </div>

        <div className="mt-12 hidden flex-col space-y-2 px-5">
          <div className="flex items-center space-x-1 text-[#7B93AF]">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 12H14"
                stroke="#7B93AF"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 8H14"
                stroke="#7B93AF"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 4H14"
                stroke="#7B93AF"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Activity Log</span>
          </div>

          <div className="flex flex-col">
            <div className="flex items-center justify-between border-b border-[#C0EAED] py-2 text-sm text-[#33475B]">
              <span>11/7/16</span>
              <span>Login at 8:00 am</span>
            </div>
            <div className="flex items-center justify-between border-b border-[#C0EAED] py-2 text-sm text-[#33475B]">
              <span>11/7/16</span>
              <span>Login at 8:00 am</span>
            </div>

            <Empty />
          </div>
        </div>

        {/* <div className="mt-2 flex justify-center px-5 text-[#7B93AF]">
          <span>See More...</span>
        </div> */}

        <div
          className={`mt-10 ${
            agent.terminationDate ? "flex" : "hidden"
          } items-center justify-center`}
        >
          <button
            type="button"
            className="rounded-full bg-gradient-to-r from-[rgba(242,84,125,1)] to-[rgba(255,121,89,1)] px-8 py-3 text-white"
          >
            Terminated Date
          </button>
        </div>
      </Drawer>

      {agent && agent.name && (
        <AgentUpdate.AgentUpdateBox
          agent={agent}
          isOpen={drawers.update}
          handleClose={() => closeDrawer("update")}
        />
      )}
    </>
  );
};

const AgentViewBtn = ({ toggleDrawer, className, children }) => {
  return (
    <>
      <button onClick={toggleDrawer} className={className || "px-2"}>
        {children || "View"}
      </button>
    </>
  );
};

const AgentView = {
  AgentViewBtn,
  AgentViewBox,
};

export default AgentView;
