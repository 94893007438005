import agentsReducer from "../components/user-management/agents/agentSlice";
import coachingReducer from "../components/coach-analysis/coachingSlice";
import { configureStore } from "@reduxjs/toolkit";
import teamsReducer from "../components/user-management/teams/teamSlice";

export const store = configureStore({
  reducer: {
    teams: teamsReducer,
    agents: agentsReducer,
    coaching: coachingReducer,
  },
});
