import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import servicesServices from "../../../services/services.services";

const initialState = {
  loading: false,
  error: undefined,
  originalDataSet: [],
  dataSet: [],
  teams: [],
  totalElements: 0,
  filters: {},
};

export const fetchAgents = createAsyncThunk(
  "agents/fetchAgents",
  async ({ ...params }) => {
    const userRes = await servicesServices.fetchUsers(params);
    const teamRes = await servicesServices.fetchTeams();

    return { team: teamRes.data.result, user: userRes.data };
  }
);

export const agentSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    setData: (state, action) => {
      state = { ...state, dataSet: action.payload };

      return state;
    },
    agentsUpdateOne: (state, action) => {
      // TODO: use url or base64 for avatar
      const { avatar, ...payload } = action.payload;
      // const { ...payload } = action.payload;
      const sourceIndex = [...(state.originalDataSet || [])].findIndex(
        (t) => t.id === payload.id
      );

      let originalDataSet = [...state.originalDataSet];
      if (sourceIndex > -1) {
        originalDataSet.splice(sourceIndex, 1, payload);
      }

      const index = [...(state.dataSet || [])].findIndex(
        (t) => t.id === payload.id
      );

      let dataSet = [...state.dataSet];
      if (index > -1) dataSet.splice(index, 1, payload);

      state = { ...state, originalDataSet, dataSet };

      return state;
    },
    agentsDeleteOne: (state, action) => {
      state.dataSet = [...state.dataSet].filter((t) => t.id !== action.payload);
      state.originalDataSet = [...state.originalDataSet].filter(
        (t) => t.id !== action.payload
      );

      return state;
    },
    agentsCreate: (state, action) => {
      state.originalDataSet = [...state.originalDataSet, action.payload];
      state.dataSet = [...state.dataSet, action.payload];

      return state;
    },
    agentFilter: (state, action) => {
      let filters = { ...state.filters };
      if (action.payload.action === "ADD") {
        filters = { ...filters, [action.payload.key]: action.payload.value };
      } else if (action.payload.action === "REMOVE") {
        delete filters[action.payload.key];
      }
      state = {
        ...state,
        filters,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAgents.pending, (state) => {
      state = { ...state, loading: true, error: undefined, totalElements: 0 };
      return state;
    });
    builder.addCase(fetchAgents.fulfilled, (state, action) => {
      const dataSet = [...action.payload.user.result].map((user) => {
        let teams = [...action.payload.team].filter((team) =>
          [...(user?.teamNames || [])].includes(team.name)
        );

        return {
          ...user,
          teamIds: [...(action.payload?.team || [])]
            .filter((team) => [...(user?.teamNames || [])].includes(team.name))
            .map((t) => t.id),
          teamNames: [...(action.payload?.team || [])].filter((team) =>
            [...(user?.teamNames || [])].includes(team.name)
          ),
          teams: teams.map((t) => ({
            ...t,
            agents: [...(action.payload.user.result || [])].filter(
              (user) => user.role.toLowerCase() === "agent"
            ),
          })),
        };
      });

      state = {
        ...state,
        loading: false,
        originalDataSet: dataSet,
        dataSet: dataSet,
        teams: [...(action.payload?.team || [])].map((t) => ({
          ...t,
          agents: [...(action.payload.user.result || [])].filter(
            // (user) => user.role.toLowerCase() === "agent" && [...(user?.teamNames || [])].includes(t.name)
            (user) => [...(user?.teamNames || [])].includes(t.name)
          ),
        })),
        totalElements:
          // action.payload.user.totalRows ||
          dataSet.length || 0,
      };
      return state;
    });
    builder.addCase(fetchAgents.rejected, (state, action) => {
      state = { ...state, loading: false, error: action.payload };
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  agentsUpdateOne,
  agentsDeleteOne,
  agentsCreate,
  agentFilter,
  setData,
} = agentSlice.actions;

export default agentSlice.reducer;
