import React, { useEffect, useState } from "react";

import Dialog from "rc-dialog";
import { fetchTeams } from "../../user-management/teams/teamSlice";
import { useDispatch, useSelector } from "react-redux";

const TeamSelect = () => {
  const [query, setQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selected, setSelected] = useState([]);

  const dispatch = useDispatch();

  const {
    loading,
    filters,
    dataSet: fillteredTeams,
    originalDataSet: teams,
    totalElements,
  } = useSelector((state) => state.teams);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAdd = (id) => {
    const tmp = [...fillteredTeams];
    const index = tmp.findIndex((team) => team.id === id);
    const team = tmp.splice(index, 1);
    // setFilteredTeams([...tmp]);
    setSelected([...selected, ...team]);
  };

  const handleRemove = (id) => {
    const tmp = [...selected];
    const index = tmp.findIndex((team) => team.id === id);
    tmp.splice(index, 1);
    setSelected([...tmp]);
    // setFilteredTeams(
    //   teams.filter(
    //     (team) => tmp.map((x) => x.id).findIndex((y) => y === team.id) === -1
    //   )
    // );
  };

  useEffect(() => {
    if (isModalOpen) dispatch(fetchTeams({ page: 1, pageSize: 10 }));
  }, [isModalOpen]);

  return (
    <>
      {selected.length > 0 ? (
        <div
          className={`mr-2 mt-2 inline-flex flex-wrap items-center rounded-full border border-cyan-300 px-4 pb-2`}
        >
          <button
            type="button"
            className="mr-2 mt-2 flex items-center truncate text-sm font-normal text-orange-400"
            onClick={showModal}
          >
            <span>Select Team</span>
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-5 w-5 flex-shrink-0 font-black text-orange-400"
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>

          {[...selected].slice(0, 3).map((team, index) => (
            <div
              key={index}
              className="mr-3 mt-2 flex items-center space-x-2 truncate last:mr-0"
            >
              <span>{team.name}</span>
              <button
                onClick={() => handleRemove(team.id)}
                type="button"
                className=""
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="inline-block h-6 w-6 cursor-pointer text-cyan-300"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </button>
            </div>
          ))}

          {selected.length > 3 && (
            <Selection selection={selected} handleClick={handleRemove} />
          )}
        </div>
      ) : (
        <button
          type="button"
          onClick={showModal}
          className="relative mr-2 mt-2 inline-block overflow-hidden truncate rounded-full bg-purple-100 px-5 py-2.5 text-sm font-semibold"
        >
          <svg
            width="31"
            height="40"
            viewBox="0 0 31 40"
            className="absolute left-0 top-0"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.7"
              d="M0 20C0 8.9543 8.9543 0 20 0H21C4 20.5 31 40 31 40H20C8.9543 40 0 31.0457 0 20Z"
              fill="url(#paint0_linear_694_1301)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_694_1301"
                x1="20"
                y1="-1.88392e-06"
                x2="33"
                y2="44.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D9CAEB" />
                <stop offset="1" stopColor="#F1EAFA" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-2 inline-block h-5 w-5 text-cyan-500"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
          <span>Select Team</span>
        </button>
      )}

      <Dialog
        visible={isModalOpen}
        className="!max-w-sm"
        onClose={handleCancel}
      >
        <div className="flex flex-col">
          <span className="text-[#33475B]">Select a Team:</span>
          <div className="relative mt-1">
            <input
              type="text"
              className="w-full rounded-full bg-[#F8F5FD] p-3.5 pr-7 text-[#33475B] placeholder:text-cyan-500 focus:outline-none"
              placeholder="Search for team name..."
              onChange={(e) => setQuery(e.target.value)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/search.svg`}
              alt=""
              className="absolute right-3.5 top-1/2 -translate-y-1/2"
            />
          </div>
        </div>
        <div className="scrollbar-cyan-500 -ml-4 -mr-4 mt-4 max-h-[256px] overflow-hidden overflow-y-auto">
          <div className="flex flex-col">
            {loading ? (
              <div className="px-4 text-center">Loading...</div>
            ) : (
              <>
                {fillteredTeams.length === 0 ? (
                  <div className="px-4 text-center">Empty</div>
                ) : (
                  <>
                    {[...fillteredTeams]
                      .filter((a) => a.name.toLowerCase().includes(query.toLowerCase()))
                      .map((team, index) => {
                        return (
                          <div
                            onClick={() => handleAdd(team.id)}
                            type="button"
                            key={team.id + "" + index}
                            className="team-item relative flex cursor-pointer items-center justify-between px-4 hover:bg-[#F8F5FD]"
                          >
                            <div className="flex w-full items-center justify-between space-x-2 border-b border-cyan-200 py-2">
                              <div className="flex items-center space-x-2">
                                <div className="flex h-8 w-8 items-center justify-center rounded-md bg-cyan-300 text-2xl font-bold text-white">
                                  {team.name.toUpperCase().substring(0, 1)}
                                </div>
                                <div className="flex items-center space-x-1">
                                  <span className="select-none text-[#33475B]">
                                    {team.name}
                                  </span>
                                  <span className="select-none text-cyan-600">
                                    ({(team.users || []).length || 0} members)
                                  </span>
                                </div>
                              </div>

                              {/* <div className="absolute right-4 top-1/2 z-10 -translate-y-1/2">
                            <a
                              href={`/#${index}`}
                              onClick={(e) => e.stopPropagation()}
                              className="analyze-btn flex items-center whitespace-nowrap rounded-full bg-orange-400 px-3 py-1.5 text-xs text-white"
                            >
                              <span>Analyze</span>
                              <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="-mr-2 h-4 w-4 flex-shrink-0 font-black text-white"
                                aria-hidden="true"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <polyline points="9 18 15 12 9 6"></polyline>
                              </svg>
                            </a>
                          </div> */}
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default TeamSelect;

const Selection = ({ selection = [], handleClick }) => {
  const [query, setQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={showModal}
        className="mt-2 truncate text-sm text-gray-400"
      >
        more ...
      </button>

      <Dialog
        visible={isModalOpen}
        className="!max-w-sm"
        onClose={handleCancel}
      >
        <div className="flex flex-col">
          <span>Selected Teams:</span>
          <div className="relative mt-1">
            <input
              type="text"
              className="w-full rounded-full bg-[#F8F5FD] p-3.5 pr-7 text-[#33475B] placeholder:text-cyan-500 focus:outline-none"
              placeholder="Search for reason for contact ..."
              onChange={(e) => setQuery(e.target.value)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/search.svg`}
              alt=""
              className="absolute right-3.5 top-1/2 -translate-y-1/2"
            />
          </div>
        </div>
        <div className="scrollbar-cyan-500 -ml-4 -mr-4 mt-4 max-h-[256px] overflow-hidden overflow-y-auto">
          <div className="flex flex-col">
            {[...selection]
              .filter((a) => a.name.includes(query))
              .map((team, index) => {
                return (
                  <div
                    onClick={() => handleClick(team.id)}
                    type="button"
                    key={team.id + "" + index}
                    className="team-item flex cursor-pointer items-center justify-between px-4 hover:bg-[#F8F5FD]"
                  >
                    <div className="flex w-full items-center justify-between space-x-2 border-b border-cyan-200 py-2">
                      <div className="flex items-center space-x-2">
                        <div className="flex h-8 w-8 items-center justify-center rounded-md bg-cyan-300 text-2xl font-bold text-white">
                          {team.name.toUpperCase().substring(0, 1)}
                        </div>
                        <div className="flex items-center space-x-1">
                          <span className="select-none text-[#33475B]">
                            {team.name}
                          </span>
                        </div>
                      </div>

                      <div className="">
                        <a
                          href={`/#${index}`}
                          onClick={(e) => e.stopPropagation()}
                          className="analyze-btn flex items-center whitespace-nowrap rounded-full bg-orange-400 px-3 py-1.5 text-xs text-white"
                        >
                          <span>Analyze</span>
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="-mr-2 h-4 w-4 flex-shrink-0 font-black text-white"
                            aria-hidden="true"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Dialog>
    </>
  );
};
