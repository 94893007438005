import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { getToken } from "../../../helpers/util";

const AuthenticationLayout = () => {
  const navigate = useNavigate();
  const isAuth = getToken();

  useEffect(() => {
    if (isAuth) navigate("/dashboard/overview");
  }, []);

  return (
    <div
      className="flex min-h-screen items-center justify-end bg-cover bg-right bg-no-repeat"
      style={{ backgroundImage: `url('/assets/images/auth-bg.jpeg')` }}
    >
      <div className="flex min-h-screen w-full items-center justify-center bg-white p-16 shadow-[-12px_4px_20px_0px_rgba(0,0,0,0.30)] lg:max-w-[640px] lg:rounded-l-[40px]">
        <div className="flex w-full max-w-sm">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthenticationLayout;
