import { getToken, setLogin, removeToken, setRequestConfig } from "./util";

import AxiosMockAdapter from "axios-mock-adapter";
import axios from "axios";

export const instance = axios.create({
  timeout: 600000,
  headers: {
    "Accept-Language": "en",
  },
});

export const instanceMock = axios.create({
  timeout: 60000,
  headers: {
    "Accept-Language": "en",
  },
});

export const instanceMockAdapter = new AxiosMockAdapter(instanceMock, {
  delayResponse: 1000,
});

class Request {
  self;

  constructor(baseUrl, headers, axiosInstance) {
    this.self = {
      baseUrl,
      headers,
      instance: axiosInstance || instance,
    };
  }

  post = (endpoint, data, config) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      data,
      method: "POST",
      headers: this.self.headers,
      ...config,
    });
  };

  get = (endpoint, params, config) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      params,
      method: "GET",
      headers: this.self.headers,
      ...config,
    });
  };

  put = (endpoint, data) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      data,
      method: "PUT",
      headers: this.self.headers,
    });
  };

  patch = (endpoint, data) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      data,
      method: "PATCH",
      headers: this.self.headers,
    });
  };

  delete = (endpoint, data) => {
    const url = `${this.self.baseUrl}${endpoint}`;
    return this.self.instance({
      url,
      data,
      method: "DELETE",
      headers: this.self.headers,
    });
  };
}

export default Request;

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const tokens = getToken();
    const language = "en";
    const { url } = config;

    const newConfig = config;
    if (
      url?.startsWith("/api") ||
      url?.startsWith(`${process.env.REACT_APP_BASE_URL}/api`)
    ) {
      if (!tokens) window.location.href = "/";

      // eslint-disable-next-line
      // @ts-ignore
      newConfig.headers.Authorization = `Bearer ${tokens.access_token}`;
      // @ts-ignore
      newConfig.headers["Accept-Language"] = language;

      setRequestConfig({
        headers: {
          Authorization: `Bearer ${tokens.access_token}`,
          "Accept-Language": language,
        },
      });
    }

    return newConfig;
    // Do something before request is sent
  },
  (error) => {
    // Do something with request error
    console.log(`error`, error);
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    response,
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (!error.response || !error.config) {
      return Promise.reject({
        errors: error,
        fingerPrint: null,
        message: "Connection error",
      });
    } else {
      const originalConfig = error.config;
      // if (error.response.status === 401 && !originalConfig._retry) {
      if (error.response.status === 401) {
        setLogin({})
        removeToken()
        window.location.href = "/";
        return Promise.reject(error);
      }

      if (
        error.response.status !== 401
      ) {
        if (error.response.data) {
          const { msg, message } = error.response.data;
          if (message) {
            return Promise.reject({
              status: error.response.status,
              errors: null,
              fingerPrint: null,
              message,
            });
          } else if (msg) {
            return Promise.reject({
              status: error.response.status,
              errors: null,
              fingerPrint: null,
              message: msg,
            });
          } else {
            return Promise.reject({
              status: error.response.status,
              errors: null,
              fingerPrint: null,
              message:
                "Connection Error",
            });
          }
        } else {
          // eslint-disable-next-line
          return Promise.reject({
            status: error.status,
            errors: null,
            fingerPrint: null,
            message: error.response.statusText,
          });
        }
      }
    }
  }
);
