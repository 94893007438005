import "./styles.css";

import React, { useEffect, useState } from "react";

import { ReactComponent as AscIcon } from "../../../assets/images/icons/asc-colorful.svg";
import { ReactComponent as DescIcon } from "../../../assets/images/icons/desc-colorful.svg";
import { Line } from "../../shared/progress/Progress";
import servicesServices from "../../../services/services.services";

const JibeSummery = () => {
  const [dataSummary, setDataSummary] = useState({
    result: {
      totalAgentsCount: 0,
      fullyJibedAgentsCount: 0,
      percentChangeLastWeek: 0,
    },
  });
  const [dataSession, setDataSession] = useState({
    result: {
      allAgentsCount: 0,
      jibeSessionsPast30DaysCount: 0,
      percentChangeLastWeek: 0,
    },
  });

  const fetchDataSummary = async () => {
    try {
      const res = await servicesServices.fetchJibeAgents();
      setDataSummary(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataSession = async () => {
    try {
      const res = await servicesServices.fetchJibeSessions();
      setDataSession(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataSummary();
    fetchDataSession();
  }, []);

  return (
    <div className="jibe-summery">
      <span className="jibe-summery-title">JIBE Summary</span>
      <div className="jibe-summery-content">
        <div className="jibe-summery-agent">
          <div className="jibe-summery-agent-detail-numeric">
            <div className="jibe-summery-agent-detail-changes">
              <span className="jibe-summery-agent-detail-changes-percent">
                {(dataSummary.result.totalAgentsCount > 0
                  ? (dataSummary.result.fullyJibedAgentsCount || 0 * 100) /
                    dataSummary.result.totalAgentsCount
                  : 0
                ).toFixed(2)}
                %
              </span>
              <div className="jibe-summery-agent-detail-changes-analysis">
                <span className="jibe-summery-agent-detail-changes-analysis-title">
                  JIBED Agent
                </span>
                <span className="jibe-summery-agent-detail-changes-analysis-count">
                  {dataSummary.result.fullyJibedAgentsCount || 0}
                </span>
                {dataSummary.result.percentChangeLastWeek > 0 ? (
                  <AscIcon className="jibe-summery-agent-detail-changes-analysis-icon" />
                ) : (
                  <DescIcon className="jibe-summery-agent-detail-changes-analysis-icon" />
                )}
              </div>
            </div>
            <div className="jibe-summery-agent-detail-total">
              <span className="jibe-summery-agent-detail-total-count">
                {dataSummary.result.totalAgentsCount || 0}
              </span>

              <span className="jibe-summery-agent-detail-total-title">
                All Agent
              </span>
            </div>
          </div>
          <div className="jibe-summery-agent-detail-progress">
            <Line
              percent={
                dataSummary.result.totalAgentsCount > 0
                  ? (dataSummary.result.fullyJibedAgentsCount || 0 * 100) /
                    dataSummary.result.totalAgentsCount
                  : 0
              }
            />
          </div>
        </div>
        <div className="jibe-summery-sessions">
          <span className="jibe-summery-sessions-count">
            {dataSession.result.jibeSessionsPast30DaysCount | 0}
          </span>
          <div className="">
            <span className="jibe-summery-sessions-title">JIBE Sessions</span>

            <div className="jibe-summery-sessions-analysis">
              {dataSession.result.percentChangeLastWeek > 0 ? (
                <AscIcon className="jibe-summery-sessions-analysis-icon" />
              ) : (
                <DescIcon className="jibe-summery-sessions-analysis-icon" />
              )}

              <span>{dataSession.result.percentChangeLastWeek}%</span>
              <span className="jibe-summery-sessions-analysis-range">30d</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JibeSummery;
