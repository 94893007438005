import React from "react";
import Segmented from "rc-segmented";

const Switch = ({ defaultValue, onChange, options, ...slug }) => {
  return (
    <>
      <Segmented
        defaultValue={defaultValue}
        onChange={onChange}
        options={[...options]}
        {...slug}
      />
    </>
  );
};

export default Switch;
