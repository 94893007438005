import "./input.css";

import React from "react";

const Primary = ({ text, children , ...slug}) => {
  return (
    <button type="button" className="comp-btn-primary" {...slug}>
      {children ? children : <span className="">{text || ""}</span>}
    </button>
  );
};

export default Primary;
